import { createQueryKeys } from "@lukemorales/query-key-factory"
import axios from "axios"
import {
    Granularity,
    InsightsResponse,
    ILossReasons,
} from "../../types/Insights"
import { DateTime } from "luxon"
import { transformSnakeKeysToCamelCase } from "../../utils/transformSnakeKeysToCamelCase"

export const insightQueries = createQueryKeys("insights", {
    get: (since: DateTime, granularity: Granularity, userIds: string[]) => ({
        queryKey: ["insights", since, granularity, userIds],
        queryFn: async () => {
            const params = getSearchParams(since.toISODate()!, userIds)
            params.append("granularity", granularity)

            const response = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/insights`,
                {
                    params,
                }
            )
            const result = response.data as InsightsResponse
            result.insights = response.data.insights.map(
                transformSnakeKeysToCamelCase
            )

            // Sort insights by display name
            result.insights = result.insights.sort((a, b) =>
                a.displayName
                    .toLowerCase()
                    .localeCompare(b.displayName.toLowerCase())
            )

            return result
        },
    }),

    getLossReasons: (since: DateTime, userIds: string[]) => ({
        queryKey: ["insights", "loss-reasons", since, userIds],
        queryFn: async () => {
            const params = getSearchParams(since.toISODate()!, userIds)
            const response = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/insights/loss-reasons`,
                {
                    params,
                }
            )
            return response.data as ILossReasons
        },
    }),
})

function getSearchParams(since: string, userIds?: string[]): URLSearchParams {
    const params = new URLSearchParams({
        since: since,
    })
    if (userIds) {
        userIds.forEach((id) => params.append("user_ids", id))
    }
    return params
}
