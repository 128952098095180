import { OptionProps, ValueContainerProps, components } from "react-select"
import Select from "react-select"
import colors from "tailwindcss/colors"
import { useRef, useState } from "react"

export interface IMultiSelectOption {
    value: string
    label: string
    color?: string
    counter?: number
}

export interface IMultiSelectOptionGroup {
    label: string
    options: IMultiSelectOption[]
}

export const SelectCustomStyle = {
    control: (provided: any) => ({
        ...provided,
        borderColor: colors.gray[200],
        borderRadius: "0.5rem",
        boxShadow: "none",
        flexWrap: "nowrap",
        "&:hover": {
            borderColor: colors.gray[300],
            backgroundColor: colors.gray[50],
        },
    }),
    menu: (provided: any) => ({
        ...provided,
        padding: "0px 4px 0px 4px",
        borderRadius: "0.5rem",
        zIndex: 50,
    }),
    option: (provided: any) => ({
        ...provided,
        backgroundColor: "white",
        color: "black",
        borderRadius: "0.5rem",
        "&:hover": {
            backgroundColor: colors.gray[100],
        },
    }),
}

export function MultiSelectFilter(props: {
    title: string
    icon: React.ReactNode
    options: IMultiSelectOption[]
    value: IMultiSelectOption[]
    onApply: (selectedOption: IMultiSelectOption[]) => void
    isLoading?: boolean
}) {
    const selectRef = useRef<any>(null)
    const [selectedOptions, setSelectedOptions] = useState<
        IMultiSelectOption[]
    >(props.value)

    return (
        <Select
            ref={selectRef}
            options={props.options}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            onMenuClose={() => props.onApply(selectedOptions)}
            components={{
                Option: MultiSelectOptions,
                ValueContainer,
            }}
            className="w-64 text-base"
            onChange={(newValue, triggeredAction) => {
                setSelectedOptions(newValue as IMultiSelectOption[])
                // immediately apply changes only when the options are cleared
                // We don't want to immediately apply changes when user de/selects an option
                if (triggeredAction.action === "clear") {
                    props.onApply([])
                }
            }}
            value={selectedOptions}
            isLoading={props.isLoading}
            styles={SelectCustomStyle}
            // @ts-ignore
            // those are custom props that we use in the ValueContainer component
            // typescript support for custom props in react-select is not great
            // so we ignore the type error here
            icon={props.icon}
            title={props.title}
        />
    )
}

export function MultiSelectOptions(props: OptionProps<IMultiSelectOption>) {
    return (
        <div>
            <components.Option {...props}>
                <div className="flex flex-row items-center justify-between">
                    <div className="flex flex-row gap-2 items-center justify-start">
                        <input
                            type="checkbox"
                            checked={props.isSelected}
                            onChange={() => null}
                        />
                        <label className="flex items-center text-base">
                            {props.data.color && (
                                <div
                                    className="w-2 h-2 rounded-full mx-2 flex-shrink-0"
                                    style={{
                                        backgroundColor: props.data.color,
                                    }}
                                ></div>
                            )}
                            {props.label}
                        </label>
                    </div>
                    {props.data.counter && (
                        <span className="bg-gray-200 rounded-full text-xs px-1">
                            {props.data.counter}
                        </span>
                    )}
                </div>
            </components.Option>
        </div>
    )
}

function ValueContainer({
    children,
    ...rest
}: ValueContainerProps<IMultiSelectOption>) {
    const selected = rest.getValue()
    // @ts-ignore
    const { title, icon } = rest.selectProps

    return (
        <MultiSelectMetaValueContainer
            valueContainerChildren={children}
            {...rest}
        >
            <div className="flex items-center gap-2">
                {icon}
                {selected.length > 1 && (
                    <div>{`any of ${selected.length} ${title}`}</div>
                )}
                {selected.length === 1 && (
                    <div className="truncate">{selected[0].label}</div>
                )}
                {selected.length === 0 && (
                    <div className="text-gray-500">{`All ${title}`}</div>
                )}
            </div>
        </MultiSelectMetaValueContainer>
    )
}

export function MultiSelectMetaValueContainer({
    children,
    valueContainerChildren,
    ...rest
}: ValueContainerProps<IMultiSelectOption> & {
    valueContainerChildren: React.ReactNode
}) {
    if (!valueContainerChildren || !Array.isArray(valueContainerChildren)) {
        // fallback to default rendering
        // but this should never happen
        return (
            <components.ValueContainer {...rest}>
                {valueContainerChildren}
            </components.ValueContainer>
        )
    }

    const [, input] = valueContainerChildren

    return (
        <>
            <components.ValueContainer {...rest}>
                <div className="flex items-center gap-2">{children}</div>
            </components.ValueContainer>

            {/* We must render the Select's input tag because the dropdown closes only when the input tag loses focus.
            Without adding it here, the dropdown will not close when the user clicks outside of the dropdown.
            We don't want to show the input to the user as they don't need it so we hide it by setting its opacity to 0 and max-height to 0.
            Unfortunately, it seems there's no way around it as react-select assumes that the input tag is always present.
            The input tag is always the second child in the children array. The first child is a list of the selected options.
            */}
            <div className="opacity-0 max-h-0">{input}</div>
        </>
    )
}
