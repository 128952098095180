import ToggleButton from "../common/ToggleButton"

export function ToggleSetting(props: {
    name: string
    description: string
    checked: boolean
    disabled?: boolean
    loading?: boolean
    disabledMessage?: string
    onChange: (checked: boolean) => void
}) {
    return (
        <div className="flex items-center">
            <ToggleButton
                checked={props.checked}
                disabled={props.disabled ?? false}
                loading={props.loading}
                onChange={(checked) => props.onChange(checked)}
            />
            <label className="flex flex-col items-start ml-2">
                <span
                    className={`font-bold ${
                        props.disabled ? "text-gray-300" : ""
                    }`}
                >
                    {props.name}
                </span>
                <span
                    className={`text-sm ${
                        props.disabled ? "text-gray-300" : "text-gray-500"
                    }`}
                >
                    {props.description}
                </span>
                {props.disabled && props.disabledMessage && (
                    <span className="bg-yellow-50 text-black text-sm font-semibold p-2 mt-2">
                        {props.disabledMessage}
                    </span>
                )}
            </label>
        </div>
    )
}
