import { createQueryKeys } from "@lukemorales/query-key-factory"
import axios from "axios"
import { ISharedCall, Media } from "../../types/Call"

export const sharedCallQueries = createQueryKeys("sharedCall", {
    byId: (sharedCallId: string) => ({
        queryKey: ["shared/calls", sharedCallId],
        queryFn: async () => {
            const response = await axios.get(
                process.env.REACT_APP_API_DOMAIN +
                    "/shared/calls/" +
                    sharedCallId
            )
            return response.data as ISharedCall
        },
    }),

    media: (sharedCallId: string) => ({
        queryKey: ["shared/calls/media", sharedCallId],
        queryFn: async () => {
            const response = await axios.get(
                process.env.REACT_APP_API_DOMAIN +
                    "/shared/calls/" +
                    sharedCallId +
                    "/media"
            )
            return response.data as Media
        },
    }),

    url: (sharedCallId: string) => ({
        queryKey: ["shared/calls/url", sharedCallId],
        queryFn: async () => {
            const response = await axios.get(
                process.env.REACT_APP_API_DOMAIN +
                    "/shared/calls/" +
                    sharedCallId +
                    "/url"
            )
            return response.data as string
        },
    }),
})
