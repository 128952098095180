import { useMemo } from "react"
import _ from "lodash"

import { Card } from "./Card"
import { useQuery } from "@tanstack/react-query"
import { queries } from "../../api/queries"
import { LoadingPulse } from "../common/LoadingPulse"
import { ICrmField } from "../crm/types/Crm"
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { DEFAULT_GROUP_NAME } from "../CustomInsights"

export function InsightsSettings(props: { orgName: string | undefined }) {
    const { data, isPending } = useQuery(queries.organization.customInsights())
    const insights = useMemo(() => {
        return _.sortBy(
            data || [],
            ["group_name", "display_name", "name"],
            "asc"
        ) as ICrmField[]
    }, [data])

    return (
        <>
            <div className="flex flex-col space-y-2 w-full">
                <h2 className="text-xl font-bold">What are Insights</h2>
                <span className="text-gray-600 text-base">
                    Insights are key points automatically captured from your
                    calls, customizable to help you track and understand the
                    information that matters most to your business.
                </span>
            </div>
            <Card
                title={`${props.orgName} Insights`}
                label="View your organization-wide insights."
            >
                {isPending ? (
                    <LoadingPulse rows={8} height="h-10" />
                ) : insights?.length > 0 ? (
                    <InsightsList insights={insights} />
                ) : (
                    <div className="p-4">
                        <span className="text-gray-600 text-base">
                            You don't have any insights yet, please reach out to
                            the team at{" "}
                            <a
                                className="underline"
                                href="mailto:support@glyphic.ai"
                            >
                                support@glyphic.ai
                            </a>
                            .
                        </span>
                    </div>
                )}
            </Card>
        </>
    )
}

function InsightsList(props: { insights: ICrmField[] }) {
    return (
        <div className="border border-gray-300 rounded-lg w-full overflow-auto">
            <div className="flex justify-between items-center p-4 italic md:hidden">
                Manage your insights on a desktop device.
            </div>
            <table className="w-full hidden md:table">
                <thead>
                    <tr className="h-16">
                        <th className="text-left px-4 py-2 w-48">Group</th>
                        <th className="text-left px-4 py-2 w-48">Name</th>
                        <th className="text-left px-4 py-2">
                            Prompt / question
                        </th>
                        <th className="text-center px-4 py-2 w-48">
                            Connected to CRM
                        </th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-300 border-t border-gray-300 text-sm">
                    {props.insights.map((insight) => (
                        <tr key={insight.name}>
                            <td className="px-4 py-2">
                                {insight.group_name || DEFAULT_GROUP_NAME}
                            </td>
                            <td className="px-4 py-2">
                                {insight.display_name || insight.name || ""}
                            </td>
                            <td className="px-4 py-2">
                                {insight.description || ""}
                            </td>
                            <td className="px-4 py-2 text-center">
                                {insight.crm_field_name ? (
                                    <FontAwesomeIcon
                                        icon={faCheckCircle}
                                        className="text-green-500 w-4 h-4"
                                        data-tooltip-content={
                                            insight.crm_field_name
                                        }
                                        data-tooltip-id="tooltip-explanation"
                                    />
                                ) : (
                                    <FontAwesomeIcon
                                        icon={faTimesCircle}
                                        className="text-gray-400 w-4 h-4"
                                    />
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}
