import { useMemo } from "react"
import { TooltipProps } from "recharts"
import tailwindColors from "tailwindcss/colors"

export function createCustomTooltip(isSelected: (option: string) => boolean) {
    const CustomTooltip: React.FC<TooltipProps<number, string>> = ({
        active,
        payload,
        label,
        labelFormatter,
    }) => {
        const selectedPayloads = useMemo(
            () =>
                payload?.filter((entry) => isSelected(entry.name as string)) ??
                [],
            [payload]
        )
        const nonZeroPayloads = useMemo(
            () =>
                selectedPayloads
                    ?.filter((entry) => !!entry.value)
                    .sort((a, b) => (b.value ?? 0) - (a.value ?? 0)),
            [selectedPayloads]
        )

        if (!active || !payload) return null

        const zeroCount = selectedPayloads.length - nonZeroPayloads.length
        const hiddenValuesCount = payload.length - selectedPayloads.length

        return (
            <div
                style={{
                    backgroundColor: "white",
                    border: "none",
                    borderRadius: "8px",
                    boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
                    padding: "12px",
                    fontFamily: "'Inter', sans-serif",
                    fontSize: "14px",
                    color: tailwindColors.gray[800],
                }}
            >
                <p
                    style={{
                        fontWeight: "600",
                        marginBottom: "8px",
                        fontSize: "16px",
                    }}
                >
                    {labelFormatter ? labelFormatter(label, payload) : label}
                </p>
                {nonZeroPayloads.map((entry, index) => (
                    <div
                        key={index}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "4px 0",
                            opacity: isSelected(entry.name as string) ? 1 : 0.5,
                        }}
                    >
                        <div
                            style={{
                                width: "12px",
                                height: "12px",
                                backgroundColor: entry.color,
                                marginRight: "8px",
                                borderRadius: "2px",
                            }}
                        />
                        <span style={{ marginRight: "8px" }}>{entry.name}</span>
                        <span style={{ fontWeight: "bold" }}>
                            {entry.value}
                        </span>
                    </div>
                ))}
                {zeroCount > 0 && (
                    <div style={{ padding: "4px 0" }}>
                        <span style={{ color: tailwindColors.gray[500] }}>
                            {zeroCount}{" "}
                            {zeroCount === 1 ? "option has" : "options have"}{" "}
                            zero values
                        </span>
                    </div>
                )}
                {hiddenValuesCount > 0 && (
                    <div style={{ padding: "4px 0" }}>
                        <span style={{ color: tailwindColors.gray[500] }}>
                            {hiddenValuesCount}{" "}
                            {hiddenValuesCount === 1
                                ? "value is"
                                : "values are"}{" "}
                            hidden
                        </span>
                    </div>
                )}
                {selectedPayloads.length === 0 && (
                    <div style={{ padding: "4px 0" }}>
                        <span style={{ color: tailwindColors.gray[500] }}>
                            No values selected
                        </span>
                    </div>
                )}
            </div>
        )
    }

    return CustomTooltip
}
