import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CrunchbaseLogo } from "../../assets/CrunchbaseLogo"
import { ICompanyInfo } from "../../types/CompanyProfile"
import { LogoLink } from "../common/LogoLink"
import { faGlobe } from "@fortawesome/free-solid-svg-icons"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { SimpleCard } from "../common/SimpleCard"
import { crmIconFromUrl } from "../crm/utils/crmIconFromUrl"

interface CompanyDetailsProps {
    info: ICompanyInfo | null
    isLoading: boolean
}

export function CompanyDetails(props: CompanyDetailsProps) {
    if (props.isLoading) {
        return <LoadingProfile />
    }

    if (!props.info) {
        return null
    }

    const revenueString = formatCurrency(props.info.annual_revenue_usd)
    const fundingString = formatCurrency(
        props.info.total_funding_usd?.toString()
    )

    return (
        <SimpleCard className="w-full p-3">
            <div className="flex flex-col md:flex-row w-full gap-2 md:gap-4">
                <div className="flex flex-nowrap w-full gap-4">
                    {props.info.image_url && (
                        <div className="flex items-center w-20 h-auto">
                            <img
                                className="h-auto w-20 rounded-lg"
                                src={props.info?.image_url}
                                alt="Company logo"
                            />
                        </div>
                    )}
                    <div className="flex flex-col w-full gap-2">
                        <div className="flex flex-col flex-nowrap">
                            <span className="text-gray-900 font-bold text-3xl leading-9">
                                {props.info.name}{" "}
                            </span>
                            <CompanyLinks info={props.info} />{" "}
                        </div>
                        <div className="hidden md:block w-full">
                            <span
                                className="text-gray-900 text-sm font-normal leading-tight tracking-tight line-clamp-1"
                                data-tooltip-id="tooltip-explanation"
                                data-tooltip-content={props.info.description}
                            >
                                {props.info.description}
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    className="text-gray-900 text-sm font-normal leading-tight tracking-tight line-clamp-1 md:hidden w-full"
                    data-tooltip-id="tooltip-explanation"
                    data-tooltip-content={props.info.description}
                >
                    {props.info.description}
                </div>
                <div className="flex divide-x divide-gray-300 min-w-fit md:h-20 items-center">
                    <CompanyAttribute
                        label="Headcount"
                        value={props.info.num_employees}
                    />
                    <CompanyAttribute
                        label="Annual revenue"
                        value={revenueString}
                    />
                    <CompanyAttribute
                        label="Total money raised"
                        value={fundingString}
                    />
                </div>
            </div>
        </SimpleCard>
    )
}

function formatCurrency(value?: string | null): string | null {
    if (!value) return null

    try {
        const numberValue = Number(value.replace(/[$,]/g, ""))
        if (Number.isNaN(numberValue)) return value

        return new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        }).format(numberValue)
    } catch (e) {
        return value
    }
}

function LoadingProfile() {
    return (
        <SimpleCard>
            <div className="flex p-2 space-x-2 animate-pulse text-gray-300 w-full items-start">
                <div className="font-bold">Loading Company Profile</div>
            </div>
        </SimpleCard>
    )
}

function CompanyLinks(props: { info: ICompanyInfo }) {
    return (
        <div className="flex gap-2 items-center">
            {props.info.crunchbase_url && (
                <LogoLink
                    url={props.info.crunchbase_url}
                    logo={<CrunchbaseLogo className="w-5 h-auto" />}
                />
            )}
            {props.info.crm_url && (
                <LogoLink
                    url={props.info.crm_url}
                    logo={
                        <FontAwesomeIcon
                            icon={crmIconFromUrl(props.info.crm_url)}
                            size="lg"
                        />
                    }
                />
            )}
            {props.info.website_url && (
                <LogoLink
                    url={props.info.website_url}
                    logo={<FontAwesomeIcon icon={faGlobe} size="lg" />}
                />
            )}
            {props.info.linkedin_url && (
                <LogoLink
                    url={props.info.linkedin_url}
                    logo={<FontAwesomeIcon icon={faLinkedin} size="lg" />}
                />
            )}
        </div>
    )
}

export function CompanyAttribute(props: {
    label: string
    value: string | null | undefined
}) {
    return (
        <div className="flex flex-col md:px-5 first:pl-0 last:pr-0 p-2 space-y-1">
            <span className="text-gray-900 text-xs font-semibold leading-none tracking-tight">
                {props.label}
            </span>
            <span className="text-gray-900 text-xs md:text-base font-normal leading-normal break-all">
                {props.value || "Unspecified"}
            </span>
        </div>
    )
}
