import { faSquare, faSquareCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export function ColoredLabelSquare({
    color,
    isSelected,
}: {
    color: string
    isSelected: boolean
}) {
    const icon = isSelected ? faSquareCheck : faSquare
    return <FontAwesomeIcon icon={icon} style={{ color: color }} />
}
