import { ReactNode, useEffect, useState } from "react"

import { Modal } from "../common/Modal"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGear } from "@fortawesome/free-solid-svg-icons"
import { ConnectButton } from "./ConnectButton"

export function IntegrationSetting(props: {
    name: string
    logo: string
    onEnable: () => Promise<void>
    onDisable: () => Promise<void>
    authorized: boolean
    config?: ReactNode
    showConfig?: boolean
    onShowConfig?: () => void
}) {
    const [showSettings, setShowSettings] = useState<boolean>(
        !!props.showConfig
    )

    // Allow parent to update whether settings is displayed
    useEffect(() => {
        setShowSettings(!!props.showConfig)
    }, [props.showConfig])

    return (
        <div className="flex justify-between items-center">
            <div className="flex flex-row gap-4 items-center">
                <img
                    src={props.logo}
                    alt={props.name}
                    className="h-10 rounded-lg"
                />{" "}
                <div className="font-bold">{props.name}</div>
            </div>

            <div className="flex flex-row items-center gap-4">
                {props.config && (
                    <div>
                        <button
                            onClick={() => {
                                setShowSettings(true)
                                if (props.onShowConfig) {
                                    props.onShowConfig()
                                }
                            }}
                            disabled={!props.authorized}
                        >
                            <FontAwesomeIcon
                                icon={faGear}
                                size="xl"
                                className={`transition-colors ${
                                    props.authorized
                                        ? "text-delta hover:text-delta-pale"
                                        : "text-gray-300 cursor-default"
                                }`}
                            />
                        </button>
                        <Modal
                            isOpen={showSettings}
                            onClose={() => setShowSettings(false)}
                        >
                            <div className="p-8">{props.config}</div>
                        </Modal>
                    </div>
                )}
                <ConnectButton
                    connected={props.authorized}
                    onConnect={props.onEnable}
                    onDisconnect={props.onDisable}
                />
            </div>
        </div>
    )
}
