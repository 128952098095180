import { MouseEvent, ReactNode } from "react"
import {
    faBullhorn,
    faInfoCircle,
    faWarning,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export enum NotificationType {
    Success,
    Error,
    Warning,
    Info,
    Broadcast,
}

export interface NotificationProps {
    notificationId: string
    highlight: string
    message: ReactNode
    type: NotificationType
    onClose: (notificationId: string) => void
}

export function Notification(props: NotificationProps) {
    const handleClose = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        props.onClose(props.notificationId)
    }

    let className: string
    let icon: typeof faInfoCircle | typeof faWarning

    switch (props.type) {
        case NotificationType.Success:
            className = "border-green-400 border bg-green-50 text-green-700"
            icon = faInfoCircle
            break
        case NotificationType.Error:
            className = "border-red-400 border bg-red-50 text-red-700"
            icon = faWarning
            break
        case NotificationType.Warning:
            className = "border-yellow-400 border bg-yellow-50 text-yellow-700"
            icon = faWarning
            break
        case NotificationType.Info:
            className = "border-blue-400 border bg-blue-50 text-blue-700"
            icon = faInfoCircle
            break
        case NotificationType.Broadcast:
            className = "border-gray-600 border bg-gray-100 text-gray-700"
            icon = faBullhorn
            break
    }

    return (
        <div
            className={
                "flex flex-row justify-between items-center p-4 gap-4 rounded-md max-w-2xl " +
                className
            }
        >
            <FontAwesomeIcon icon={icon} className="text-lg" />
            <p className="flex-shrink break-words overflow-hidden first-letter:capitalize text-lg max-w-fit">
                {props.highlight && <strong>{props.highlight} &nbsp;</strong>}
                {props.message}
            </p>
            <button
                className="hover:font-bold bg-transparent"
                onClick={handleClose}
            >
                &#10005;
            </button>
        </div>
    )
}
