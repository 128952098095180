import { useEffect } from "react"
import { NotificationType } from "../components/common/Notifcations"
import { useNotification } from "../providers/NotificationProvider"
import { useQuery } from "@tanstack/react-query"
import { queries } from "../api/queries"

export function NewVersionChecker() {
    const { addNotification } = useNotification()
    const currentVersion = process.env.REACT_APP_NETLIFY_BUILD_ID

    // Periodically ping maverick api to check if there's a new frontend version

    const refetchInterval = 1000 * 60 * 60 // 1 hour

    const { data: latestVersion } = useQuery({
        ...queries.version.frontendVersion(),
        staleTime: refetchInterval,
        refetchInterval: refetchInterval,
        refetchIntervalInBackground: false,
        initialData: currentVersion,
        enabled: !!currentVersion, // Disable when no current version (ie on local dev)
    })

    useEffect(() => {
        if (latestVersion === currentVersion) {
            return
        }

        addNotification(
            "New version available",
            <span>
                <span
                    className="underline hover:cursor-pointer hover:font-semibold"
                    onClick={() => window.location.reload()}
                >
                    Refresh the page
                </span>{" "}
                <span>to update.</span>
            </span>,
            NotificationType.Info
        )
    }, [addNotification, latestVersion, currentVersion])

    return null
}
