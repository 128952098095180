import { CaseStudyContent } from "../components/call-prep/CaseStudies"
import { IOrganization, IParticipant } from "./Call"
import { ICompanyProfile } from "./CompanyProfile"

export interface IGeneratedCallPrepResponse {
    prep_sheet: ICallPrepData
    url: string
}

export enum CueType {
    Reminder = "reminder",
    Question = "question",
    Objection = "objection",
}

export interface ICallInfo {
    title: string
    start_time: string
    end_time: string
    meeting_url: string | null
}

export interface ICue {
    id: number
    type: CueType
    text: string
    reason: string
}

export interface ICallPrepData {
    _id: string
    call_info: ICallInfo
    company_metadata: IOrganization | null
    company: ICompanyProfile
    participants: IParticipant[]
    cues: ICue[]
    case_studies: CaseStudyContent[]
    suggestions: string[] | null
}
