import { useEffect, useState } from "react"
import { IUpcomingCall } from "../../types/UpcomingCall"
import { OptionalUpcomingCallRecordButton } from "./UpcomingCallRecordButton"
import { getLocalDateTime, getSimpleTime } from "../../utils/datetime"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faChevronLeft,
    faChevronRight,
} from "@fortawesome/free-solid-svg-icons"
import LoadingSpinner from "../common/LoadingSpinner"
import { DateTime } from "luxon"
import { PrimaryButton, SecondaryButton } from "../common/Buttons"
import { Link } from "react-router-dom"
import { createCallPrepSheet } from "../call-prep/CallPrepPage"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../common/Notifcations"
import { sendBotToMeeting } from "../../utils/sendBotToMeeting"
import { AddBotIcon } from "../../assets/icons/AddBotIcon"
import { AnimatedLockIcon } from "../call-page/TogglePrivacyButton"
import { queries } from "../../api/queries"
import axios from "axios"

interface IUpcomingCalls {
    callsByWeek: Map<string, IUpcomingCall[]>
    firstDate: DateTime
    lastDate: DateTime
}

export function UpcomingCalls() {
    const [upcomingCalls, setUpcomingCalls] = useState<IUpcomingCalls | null>(
        null
    )
    const [selectedWeek, setSelectedWeek] = useState<string>("")

    const { data: calls, isPending } = useQuery(queries.upcomingCalls.list())

    useEffect(() => {
        if (!calls || calls.length === 0) {
            setUpcomingCalls(null)
            return
        }

        const callDates = calls.map((call) => getLocalDateTime(call.start_time))
        const firstDate = callDates.reduce((acc, date) =>
            date < acc ? date : acc
        )
        const lastDate = callDates.reduce((acc, date) =>
            date > acc ? date : acc
        )

        const callsByWeek = calls.reduce((acc, call) => {
            const week = getLocalDateTime(call.start_time).toFormat("yyyy-WW")
            if (acc.has(week)) {
                acc.get(week)!.push(call)
            } else {
                acc.set(week, [call])
            }
            return acc
        }, new Map<string, IUpcomingCall[]>())

        setUpcomingCalls({
            callsByWeek,
            firstDate,
            lastDate,
        })
        setSelectedWeek(callsByWeek.keys().next().value)
    }, [calls])

    if (isPending) return <LoadingSpinner />
    if (!upcomingCalls) {
        return (
            <div className="flex-col items-center justify-center text-center space-y-5 pt-20 text-gray-500">
                <div>No upcoming appointments found in your calendar.</div>
                <div>
                    Once appointments are added to your Google or Outlook
                    calendar they will appear here.
                </div>
            </div>
        )
    }

    return (
        <div className="p-6 space-y-6">
            <div className="flex flex-row justify-between">
                <h1 className="text-2xl font-bold">Your upcoming calls</h1>
                <WeekSelectionButton
                    weeks={Array.from(upcomingCalls.callsByWeek.keys())}
                    minDate={upcomingCalls.firstDate}
                    maxDate={upcomingCalls.lastDate}
                    selectWeek={setSelectedWeek}
                />
            </div>
            <div className="my-10">
                <WeekView
                    calls={upcomingCalls.callsByWeek.get(selectedWeek)!}
                />
            </div>
        </div>
    )
}

function WeekView(props: { calls: IUpcomingCall[] }) {
    const daysToCalls = props.calls.reduce((acc, call) => {
        const day = getLocalDateTime(call.start_time).toFormat("EEEE d LLLL")
        if (acc.has(day)) {
            acc.get(day)!.push(call)
        } else {
            acc.set(day, [call])
        }
        return acc
    }, new Map<string, IUpcomingCall[]>())

    return (
        <div className="space-y-8">
            {Array.from(daysToCalls).map(([day, calls]) => (
                <DayView key={day} day={day} calls={calls} />
            ))}
        </div>
    )
}

function DayView(props: { day: string; calls: IUpcomingCall[] }) {
    return (
        <div className="bg-white rounded-lg shadow-md shadow-gray-200 divide-y-[1px] divide-gray-200">
            <div className="bg-gray-200 rounded-t-lg font-semibold text-lg py-2 px-3">
                {props.day}
            </div>
            {props.calls.map((call) => (
                <UpcomingCall key={call.id} call={call} />
            ))}
        </div>
    )
}

function UpcomingCall(props: { call: IUpcomingCall }) {
    const { call } = props
    const start = getSimpleTime(call.start_time)
    const end = getSimpleTime(call.end_time)

    const isNowOrSoon = isCallNowOrSoon(call)

    return (
        <>
            <div className="flex flex-col md:flex-row justify-between p-2 items-center gap-2 w-full">
                <div className="flex flex-row gap-2 w-full">
                    <div className="text-gray-600 w-40 whitespace-nowrap">
                        {start} - {end}
                    </div>
                    <div className="flex items-center gap-2 w-full">
                        <div className="font-semibold">{call.title}</div>
                        {isNowOrSoon && call.meeting_url && (
                            <a
                                className="px-2 py-1 text-blue-500 text-xs text-center font-semibold rounded-full border border-blue-500 hover:bg-blue-500 hover:text-white transition duration-300 ease-in-out whitespace-nowrap"
                                href={call.meeting_url}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <span> Join meeting </span>
                            </a>
                        )}
                    </div>
                </div>
                <div className="flex flex-column justify-end w-full md:w-fit gap-2">
                    {call.is_external && <PrepSheetButton call={call} />}
                    <PrivateCallButton call={call} />
                    {isNowOrSoon && (
                        <SendBotButton
                            meetingId={call.id}
                            meetingUrl={call.meeting_url}
                        />
                    )}
                    <OptionalUpcomingCallRecordButton call={call} />
                </div>
            </div>
        </>
    )
}

function PrivateCallButton(props: { call: IUpcomingCall }) {
    const queryClient = useQueryClient()
    const { addNotification } = useNotification()

    const togglePrivacy = useMutation({
        mutationFn: async () => {
            return await axios.put(
                `${process.env.REACT_APP_API_DOMAIN}/call_bot/calendar/meetings/${props.call.id}`,
                {
                    is_private: !props.call.metadata.is_private,
                }
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: queries.upcomingCalls.list().queryKey,
            })
            addNotification(
                "Call privacy updated",
                `The call is now ${
                    props.call.metadata.is_private ? "public" : "private"
                }.`,
                NotificationType.Success
            )
        },
        onError: () => {
            addNotification(
                "Failed to update call privacy",
                "Please try again later.",
                NotificationType.Error
            )
        },
    })

    return (
        <SecondaryButton
            onClick={() => togglePrivacy.mutate()}
            data-tooltip-id="tooltip-explanation"
            data-tooltip-content={
                props.call.metadata.is_private ? "Make public" : "Make private"
            }
            className="flex flex-row items-center hover:underline group px-2"
        >
            <AnimatedLockIcon isPrivate={props.call.metadata.is_private} />
        </SecondaryButton>
    )
}

function PrepSheetButton(props: { call: IUpcomingCall }) {
    // Displays view or generate prep sheet button if appropriate
    const [prepSheetUrl, setPrepSheetUrl] = useState<string | null>(
        props.call.prep_sheet_url
    )
    const [prepGenerating, setPrepGenerating] = useState<boolean>(false)

    async function handleGenerateClicked() {
        setPrepGenerating(true)
        const newPrepSheet = await createCallPrepSheet(props.call.id)
        setPrepSheetUrl(newPrepSheet.url)
        setPrepGenerating(false)
    }

    if (prepSheetUrl !== null) {
        // Prep sheet already exists
        return (
            <Link
                to={prepSheetUrl}
                state={{ meetingId: props.call.id }}
                className="flex whitespace-nowrap"
            >
                <PrimaryButton>Prep sheet</PrimaryButton>
            </Link>
        )
    }

    // Prep sheet doesn't yet exist, but one can be generated
    return (
        <SecondaryButton
            className="flex whitespace-nowrap"
            onClick={handleGenerateClicked}
            disabled={prepGenerating}
        >
            {prepGenerating ? "Generating..." : "Generate prep sheet"}
        </SecondaryButton>
    )
}

export function SendBotButton(props: {
    meetingId: string
    meetingUrl: string | null
}) {
    const [loading, setLoading] = useState(false)
    const { addNotification } = useNotification()

    const sendBot = async () => {
        try {
            setLoading(true)
            await sendBotToMeeting(props.meetingId, props.meetingUrl!)
            addNotification(
                "🚀 The copilot is on its way to record the meeting. This may take a few seconds.",
                "It may need to be let in by the meeting host.",
                NotificationType.Success
            )
        } catch (error) {
            addNotification(
                "The copilot could not be sent to the meeting. Please try again.",
                "",
                NotificationType.Error
            )
        } finally {
            setLoading(false)
        }
    }

    return (
        <div
            data-tooltip-id="tooltip-explanation"
            data-tooltip-content={
                !props.meetingUrl
                    ? "No meeting URL. Add one in your calendar to send the copilot to the meeting"
                    : ""
            }
            className="flex"
        >
            <SecondaryButton
                className="flex gap-2 items-center h-fit whitespace-nowrap"
                onClick={sendBot}
                disabled={loading || !props.meetingUrl}
            >
                <AddBotIcon className="w-4 h-4" />
                Send copilot
            </SecondaryButton>
        </div>
    )
}

function WeekSelectionButton(props: {
    weeks: string[]
    minDate: DateTime
    maxDate: DateTime
    selectWeek: (week: string) => void
}) {
    const [selectedWeekIndex, setSelectedWeekIndex] = useState<number>(0)
    const isLastWeek = selectedWeekIndex === props.weeks.length - 1
    const isFirstWeek = selectedWeekIndex === 0
    return (
        <div
            className="min-w-[150px] flex flex-row items-center justify-between hover:none
                     text-gray-700 text-sm rounded-xl px-2 bg-white shadow-md shadow-gray-200"
        >
            <button
                onClick={() => {
                    setSelectedWeekIndex(selectedWeekIndex - 1)
                    props.selectWeek(props.weeks[selectedWeekIndex - 1])
                }}
                disabled={isFirstWeek}
                className="disabled:opacity-50"
            >
                <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            {getDaysRangeFromWeekNumber(
                props.weeks[selectedWeekIndex],
                isFirstWeek ? props.minDate : undefined,
                isLastWeek ? props.maxDate : undefined
            ).join(" - ")}
            <button
                onClick={() => {
                    setSelectedWeekIndex(selectedWeekIndex + 1)
                    props.selectWeek(props.weeks[selectedWeekIndex + 1])
                }}
                disabled={isLastWeek}
                className="disabled:opacity-50"
            >
                <FontAwesomeIcon icon={faChevronRight} />
            </button>
        </div>
    )
}

function getDaysRangeFromWeekNumber(
    week: string,
    minDate?: DateTime,
    maxDate?: DateTime
): [string, string] {
    const [year, weekNumber] = week.split("-").map((s) => parseInt(s))
    const firstDay = DateTime.fromObject({
        weekYear: year,
        weekNumber: weekNumber,
    }).startOf("week")

    const lastDay = DateTime.fromObject({
        weekYear: year,
        weekNumber: weekNumber,
    }).endOf("week")

    return [
        (minDate ? DateTime.max(firstDay, minDate) : firstDay).toFormat(
            "dd LLL"
        ),
        (maxDate ? DateTime.min(lastDay, maxDate) : lastDay).toFormat("dd LLL"),
    ]
}

export function isCallNowOrSoon(call: IUpcomingCall) {
    const now = DateTime.local()
    const isNowOrSoon =
        now >= getLocalDateTime(call.start_time).minus({ minutes: 5 }) &&
        now <= getLocalDateTime(call.end_time)
    return isNowOrSoon
}
