import { useCallback, useMemo } from "react"
import axios from "axios"
import { ResourceType } from "../types/ResourceTypes"
import { Thread } from "../types/ThreadTypes"

interface ThreadsApiHook {
    getThreads: () => Promise<Thread[]>
    createThread: (body?: Record<string, any>) => Promise<Thread>
}

export const useThreadsApi = (
    resourceType?: ResourceType,
    id?: string
): ThreadsApiHook => {
    const threadsUrl = useMemo(
        () =>
            resourceType && id
                ? `${process.env.REACT_APP_API_DOMAIN}/${resourceType}/${id}/askglyphic/threads`
                : `${process.env.REACT_APP_API_DOMAIN}/organization/askglyphic/threads`,
        [resourceType, id]
    )

    const getThreads = useCallback(async () => {
        const response = await axios.get(threadsUrl)
        return response.data as Thread[]
    }, [threadsUrl])

    const createThread = useCallback(
        async (body?: Record<string, any>) => {
            const response = await axios.post(threadsUrl, body)
            return response.data as Thread
        },
        [threadsUrl]
    )

    return {
        getThreads,
        createThread,
    }
}
