import { useEffect, useState } from "react"
import { useFeedbackApi } from "./hooks/useFeedbackApi"
import { useQuickActionsApi } from "./hooks/useQuickActionsApi"
import { ResourceType } from "./types/ResourceTypes"
import { AskGlyphicThread } from "./AskGlyphicThread"
import AskGlyphicTextBox from "./AskGlyphicTextBox"
import { Message, Thread } from "./types/ThreadTypes"
import { QUESTION_SUGGESTIONS } from "./question-suggestions"
import { AskGlyphicHeader } from "./AskGlyphicHeader"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { AnswerFeedback } from "./types/QuestionTypes"
import { Suggestions } from "./Suggestions"
import { QuickActionsArea } from "./QuickActionsArea"
import { SecondaryButton } from "../common/Buttons"
import { NewQuickActionModal } from "./QuickActionModals"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBolt } from "@fortawesome/free-solid-svg-icons"
import { SimpleCard } from "../common/SimpleCard"
import { AccessLevel } from "./types/QuickActionTypes"
import { useThread } from "./hooks/useThread"
import { useThreadsApi } from "./hooks/useThreadsApi"
import { PriorityButton } from "../common/Buttons"
import CompanyAskGlyphicTextBox from "./CompanyAskGlyphicTextBox"

interface AskGlyphicProps {
    resourceId: string
    type: ResourceType
    isReadOnly?: boolean
    sendEmail: (message: string) => Promise<void>
}

export default function AskGlyphic(props: AskGlyphicProps) {
    const {
        quickActions,
        createQuickAction,
        deleteQuickAction,
        editQuickAction,
    } = useQuickActionsApi(props.type)
    const { sendFeedback } = useFeedbackApi(props.type, props.resourceId)
    const { getThreads, createThread } = useThreadsApi(
        props.type,
        props.resourceId
    )
    const {
        sendMessage,
        isLoading: receivingResponse,
        rerunThread,
    } = useThread({
        resourceType: props.type,
        resourceId: props.resourceId,
        onError: (error) => {
            console.error(error)
            setMessages((prev) => prev.slice(0, -1))
            setError("Failed to generate a response")
        },
        onStreamUpdate: (delta) => {
            setMessages((prev) => {
                const lastMessage = prev[prev.length - 1]
                if (lastMessage && lastMessage.role === "ai") {
                    return [
                        ...prev.slice(0, -1),
                        {
                            ...lastMessage,
                            content: lastMessage.content + delta,
                        },
                    ]
                } else {
                    return prev
                }
            })
        },
    })
    const queryClient = useQueryClient()

    const { data: pastThreads } = useQuery<Thread[]>({
        queryKey: [props.type, props.resourceId, "threads"],
        queryFn: props.isReadOnly ? () => [] : () => getThreads(),
    })
    const pastThreadsOrEmpty = pastThreads || []
    const [dealIdFilter, setDealIdFilter] = useState<string | undefined>(
        undefined
    )
    const [inputMessage, setInputMessage] = useState("")
    const [threadId, setThreadId] = useState<string | null>(null)
    const [messages, setMessages] = useState<Message[]>([])
    const [threadExpanded, setThreadExpanded] = useState(true)
    const [error, setError] = useState<string | null>(null)

    const submitFeedbackForThread = async (feedback: AnswerFeedback) => {
        if (!threadId) return
        await sendFeedback(threadId, feedback)
    }

    // clear state if id changes
    useEffect(() => {
        setThreadId(null)
        setMessages([])
        setInputMessage("")
        setThreadExpanded(true)
        setError(null)
        setDealIdFilter(undefined)
    }, [props.resourceId])

    const submitMessage = async (message: string): Promise<void> => {
        setMessages((prev) => [
            ...prev,
            {
                role: "human",
                content: message,
            },
        ])
        if (!threadId) {
            const newThread = await createThread({
                crm_deal_id: dealIdFilter,
            })
            setThreadId(newThread.id)
            await sendMessage(newThread.id, message)
        } else {
            await sendMessage(threadId, message)
        }
    }

    useEffect(() => {
        if (receivingResponse) {
            setError(null)
            setMessages((prev) => [
                ...prev,
                {
                    role: "ai",
                    content: "",
                },
            ])
        }
    }, [receivingResponse])

    const startOrContinueThread = (thread?: Thread): void => {
        setThreadId(thread?.id || null)
        setMessages(thread?.messages || [])
        setInputMessage("")
        setThreadExpanded(true)
        if (thread) {
            setDealIdFilter(thread.crm_deal_id_filter || undefined)
        }
        queryClient.invalidateQueries({
            queryKey: [props.type, props.resourceId, "threads"],
        })
    }

    const submitQuickAction = async (message: string): Promise<void> => {
        startOrContinueThread()
        return await submitMessage(message)
    }

    const placeholder =
        messages.length > 0
            ? "Continue the conversation..."
            : getTextBoxPlaceholder(props.type, dealIdFilter)
    const showingConversation = messages.length > 0

    return (
        <div id="frigade-ask-glyphic" className="space-y-2">
            <SimpleCard className="p-4">
                <div className="text-base flex flex-col space-y-4 w-full">
                    <AskGlyphicHeader
                        pastThreads={pastThreadsOrEmpty}
                        startNewThread={startOrContinueThread}
                        selectPastThread={startOrContinueThread}
                        hasOpenThread={messages.length > 0}
                        threadExpanded={threadExpanded}
                        expandCollapseThread={() =>
                            setThreadExpanded((prev) => !prev)
                        }
                        receivingResponse={receivingResponse}
                    />

                    <div className="space-y-3 animate-fadeInUp">
                        {props.isReadOnly !== true && (
                            <QuickActionsArea
                                type={props.type}
                                setQuestion={submitQuickAction}
                                savedQuickActions={quickActions}
                                deleteQuickAction={deleteQuickAction}
                                editQuickAction={editQuickAction}
                                createQuickAction={createQuickAction}
                                disabled={receivingResponse}
                            />
                        )}
                        {!showingConversation && (
                            <Suggestions
                                suggestedQuestions={
                                    QUESTION_SUGGESTIONS[props.type]
                                }
                                setQuestion={submitQuickAction}
                            />
                        )}
                    </div>

                    {showingConversation && threadExpanded && (
                        <>
                            <AskGlyphicThread
                                messages={messages}
                                receivingResponse={receivingResponse}
                                submitFeedback={submitFeedbackForThread}
                                isReadOnly={props.isReadOnly}
                                sendEmail={props.sendEmail}
                            />

                            {error && (
                                <div className="mt-4 p-3 bg-red-50 border border-red-200 rounded-md flex items-center justify-between">
                                    <span className="text-red-600 text-sm">
                                        {error}
                                    </span>
                                    <PriorityButton
                                        onClick={() =>
                                            threadId && rerunThread(threadId)
                                        }
                                        className="ml-4 text-sm"
                                    >
                                        Retry
                                    </PriorityButton>
                                </div>
                            )}

                            {threadId &&
                                !receivingResponse &&
                                !props.isReadOnly &&
                                !error && (
                                    <CreateQuickAction
                                        threadId={threadId}
                                        resourceType={props.type}
                                        createQuickAction={createQuickAction}
                                    />
                                )}
                        </>
                    )}
                </div>
            </SimpleCard>
            {threadExpanded && (
                <div className="text-base sticky bottom-0">
                    {props.type === ResourceType.Companies ? (
                        <CompanyAskGlyphicTextBox
                            companyDomain={props.resourceId}
                            placeholder={placeholder}
                            input={inputMessage}
                            setInput={setInputMessage}
                            submit={submitMessage}
                            dealIdFilter={dealIdFilter}
                            setDealIdFilter={setDealIdFilter}
                            dealSelectDisabled={threadId !== null}
                        />
                    ) : (
                        <AskGlyphicTextBox
                            placeholder={placeholder}
                            input={inputMessage}
                            setInput={setInputMessage}
                            submit={submitMessage}
                        />
                    )}
                </div>
            )}
        </div>
    )
}

interface CreateQuickActionProps {
    threadId: string
    resourceType: ResourceType
    createQuickAction: (
        title: string,
        accessLevel: AccessLevel,
        question: string
    ) => Promise<void>
}

function CreateQuickAction({
    threadId,
    resourceType,
    createQuickAction,
}: CreateQuickActionProps) {
    const [showNewQuickAction, setShowNewQuickAction] = useState(false)

    const handleNewQuickAction = () => setShowNewQuickAction(true)
    const handleCloseQuickAction = () => setShowNewQuickAction(false)
    const handleCreateQuickAction = async (
        title: string,
        accessLevel: AccessLevel,
        question: string
    ) => {
        await createQuickAction(title, accessLevel, question)
        setShowNewQuickAction(false)
    }

    if (showNewQuickAction) {
        return (
            <NewQuickActionModal
                threadId={threadId}
                resourceType={resourceType}
                isOpen={showNewQuickAction}
                onClose={handleCloseQuickAction}
                saveQuickAction={handleCreateQuickAction}
            />
        )
    }

    return (
        <div className="flex justify-end">
            <SecondaryButton
                className="w-fit space-x-1"
                onClick={handleNewQuickAction}
            >
                <FontAwesomeIcon icon={faBolt} className="text-yellow-500" />
                <span id="frigade-create-quick-action">
                    Create a quick action
                </span>
            </SecondaryButton>
        </div>
    )
}

function getTextBoxPlaceholder(type: ResourceType, dealId?: string): string {
    switch (type) {
        case ResourceType.Companies:
            return `Ask anything related to this ${
                dealId ? "deal" : "company"
            }...`
        case ResourceType.Calls:
        case ResourceType.SharedCalls:
            return "Ask anything related to this call..."
    }
}
