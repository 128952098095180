import { useQuery } from "@tanstack/react-query"
import { IActivityStatsResponse } from "../../types/activity"
import { DateTime } from "luxon"
import axios from "axios"
import { SecondaryButton } from "../common/Buttons"
import { Link } from "react-router-dom"
import { displaySecondsAsHours } from "../../utils/datetime"
import { LoadingPulse } from "../common/LoadingPulse"
import { Meter } from "../activity/ActivityPage"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faArrowDown,
    faArrowUp,
    faChartSimple,
    faMinus,
} from "@fortawesome/free-solid-svg-icons"
import { LightEmptyArea } from "./EmptyArea"
import clsx from "clsx"
import { useUser } from "../../providers/UserProvider"
import { hasPermission } from "../../utils/Permissions"
import { Permission } from "../../types/Permission"

interface IActivityStatsPair {
    lastWeek: IActivityStatsResponse
    prevWeek: IActivityStatsResponse
}

interface IActivityChange {
    value: number
    units: string
    neutralColour: boolean // Whether a netural colour should always be used, rather than green/red for positive/negative
}

export function MyActivity() {
    const now = DateTime.now()
    // Round to start of hour to prevent rerendering too frequently
    const oneWeekAgo = now.minus({ week: 1 }).startOf("hour")
    const twoWeeksAgo = now.minus({ week: 2 }).startOf("hour")

    const {
        data: activityStatsPair,
        isPending,
        isError,
    } = useQuery<IActivityStatsPair>({
        queryKey: ["my-activity", oneWeekAgo],
        queryFn: async () => {
            const include_internal_calls = true
            const only_me = true

            const [lastWeekActivity, prevWeekActivity] = await Promise.all([
                axios.get(`${process.env.REACT_APP_API_DOMAIN}/activity/`, {
                    params: {
                        since: oneWeekAgo,
                        include_internal_calls: include_internal_calls,
                        only_me: only_me,
                    },
                }),
                axios.get(`${process.env.REACT_APP_API_DOMAIN}/activity/`, {
                    params: {
                        since: twoWeeksAgo,
                        until: oneWeekAgo,
                        include_internal_calls: include_internal_calls,
                        only_me: only_me,
                    },
                }),
            ])
            return {
                lastWeek: lastWeekActivity.data,
                prevWeek: prevWeekActivity.data,
            }
        },
    })

    if (isPending) {
        return (
            <div>
                <Header />
                <div className="bg-indigo-100 rounded-lg p-4">
                    <LoadingPulse rows={2} color="bg-indigo-200" />
                </div>
            </div>
        )
    }
    if (
        isError ||
        activityStatsPair.lastWeek.users.length === 0 ||
        activityStatsPair.prevWeek.users.length === 0
    ) {
        return null
    }

    const {
        num_calls,
        total_call_duration_seconds,
        median_talk_speed_in_words_per_minute,
        median_talk_time_percentage,
    } = activityStatsPair.lastWeek.users[0].stats

    if (num_calls === 0) {
        return (
            <div>
                <Header />
                <LightEmptyArea>
                    You haven't had any calls this week 😕
                </LightEmptyArea>
            </div>
        )
    }

    const {
        num_calls_change,
        call_duration_change,
        talk_speed_change,
        talk_ratio_change,
    } = getActivityChanges(activityStatsPair)

    const talkSpeed = `${Math.round(
        median_talk_speed_in_words_per_minute
    )} words /m`
    const totalCallTime = displaySecondsAsHours(total_call_duration_seconds)
    return (
        <div>
            <Header />
            <div className="flex bg-indigo-100 rounded-lg divide-x-2 gap-12 p-6 divide-gray-300 justify-start overflow-auto">
                <LargeNumberStat
                    label="Number of calls"
                    value={num_calls}
                    change={num_calls_change}
                />
                <div className="flex justify-between">
                    {/* A separator, needed to simplify the divide dist */}
                    <div className="w-12 flex-shrink" />
                    <div className="grid grid-cols-2 gap-6">
                        <Stat
                            label="Total call time"
                            value={totalCallTime}
                            change={call_duration_change}
                        />
                        <Stat
                            label="Talk speed"
                            value={talkSpeed}
                            change={talk_speed_change}
                        />
                        <div className="col-span-2">
                            <MeterStat
                                label="Talk:Listen ratio"
                                value={median_talk_time_percentage}
                                change={talk_ratio_change}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function getActivityChanges(stats: IActivityStatsPair) {
    const {
        num_calls,
        total_call_duration_seconds,
        median_talk_speed_in_words_per_minute,
        median_talk_time_percentage,
    } = stats.lastWeek.users[0].stats

    const {
        num_calls: prev_num_calls,
        total_call_duration_seconds: prev_total_call_duration_seconds,
        median_talk_speed_in_words_per_minute:
            prev_median_talk_speed_in_words_per_minute,
        median_talk_time_percentage: prev_median_talk_time_percentage,
    } = stats.prevWeek.users[0].stats

    const diff_num_calls = num_calls - prev_num_calls
    const diff_total_call_duration_seconds =
        total_call_duration_seconds - prev_total_call_duration_seconds
    const diff_median_talk_speed_in_words_per_minute =
        median_talk_speed_in_words_per_minute -
        prev_median_talk_speed_in_words_per_minute
    const diff_median_talk_time_percentage =
        median_talk_time_percentage - prev_median_talk_time_percentage

    const num_calls_change: IActivityChange = {
        value: diff_num_calls,
        units: "",
        neutralColour: false,
    }
    const call_duration_change: IActivityChange = {
        value: Math.round(diff_total_call_duration_seconds / 3600),
        units: "h",
        neutralColour: false,
    }
    const talk_speed_change: IActivityChange = {
        value: Math.round(diff_median_talk_speed_in_words_per_minute),
        units: " wpm",
        neutralColour: true,
    }
    const talk_ratio_change: IActivityChange = {
        value: Math.round(diff_median_talk_time_percentage),
        units: "%",
        neutralColour: true,
    }

    return {
        num_calls_change,
        call_duration_change,
        talk_speed_change,
        talk_ratio_change,
    }
}

function MeterStat(props: {
    label: string
    value: number
    change: IActivityChange
}) {
    return (
        <div className="flex flex-col space-y-2">
            <div className="font-semibold">{props.label}</div>
            <div className="flex space-x-2 items-center">
                <div>{Math.round(props.value)}%</div>
                <div className="w-48">
                    <Meter pct={props.value} />
                </div>
                <div>{Math.round(100 - props.value)}%</div>
                <ChangeIndicator change={props.change} />
            </div>
        </div>
    )
}

function LargeNumberStat(props: {
    label: string
    value: string | number
    change: IActivityChange
}) {
    return (
        <div className="flex flex-col gap-1 justify-center items-start">
            <div className="flex gap-2 items-end">
                <div className="text-6xl">{props.value.toString()}</div>
                <ChangeIndicator change={props.change} />
            </div>
            <div className="font-semibold">{props.label}</div>
        </div>
    )
}

function Stat(props: {
    label: string
    value: string | number
    change: IActivityChange
}) {
    return (
        <div className="flex flex-col space-y-2">
            <div className="font-semibold">{props.label}</div>
            <div className="flex flex-wrap gap-1">
                <div>{props.value.toString()}</div>
                <ChangeIndicator change={props.change} />
            </div>
        </div>
    )
}

function Header() {
    const user = useUser()
    const canViewActivityPage =
        user && hasPermission(user, Permission.VIEW_ACTIVITY_PAGE)
    return (
        <div className="flex justify-between items-center mb-3">
            <h3 className="text-lg font-bold">Talk stats for the past week</h3>
            {canViewActivityPage && (
                <SecondaryButton>
                    <Link
                        to="/activity"
                        className="flex items-center space-x-2 whitespace-nowrap"
                    >
                        <FontAwesomeIcon icon={faChartSimple} />
                        <span>View all stats</span>
                    </Link>
                </SecondaryButton>
            )}
        </div>
    )
}

function ChangeIndicator(props: { change: IActivityChange }) {
    if (props.change.value === 0) {
        return null
    }
    const icon =
        props.change.value === 0
            ? faMinus
            : props.change.value > 0
            ? faArrowUp
            : faArrowDown

    let colour: string
    if (props.change.neutralColour || props.change.value === 0) {
        colour = "text-violet-500 bg-violet-50 border-violet-200"
    } else {
        if (props.change.value > 0) {
            colour = "text-green-500 bg-green-100 border-green-200"
        } else {
            colour = "text-red-500 bg-red-100 border-red-200"
        }
    }

    return (
        <span
            className={clsx(
                "flex items-center gap-2 py-[2px] px-[6px] rounded-l-full rounded-r-full h-fit border text-sm font-semibold",
                colour
            )}
        >
            <FontAwesomeIcon icon={icon} />
            <span className="whitespace-nowrap">
                {props.change.value}
                {props.change.units}
            </span>
        </span>
    )
}
