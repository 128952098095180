import { UseQueryResult } from "@tanstack/react-query"
import { InsightResult, InsightsResponse } from "../../../types/Insights"
import colors from "tailwindcss/colors"
import { LoadingPulse } from "../../common/LoadingPulse"
import { scrollToInsight } from "../utils/navigation"
import { useState } from "react"
import { ToggleExpandedViewButton } from "../line-chart/ToggleExpandedViewButton"
import { IStrategicInsightNavigationItem } from "../utils/navigation"
import _ from "lodash"

export const WINNING_FORMULA_NAV_ITEM: IStrategicInsightNavigationItem = {
    id: "winning-formula",
    displayName: "Winning formula",
    isSummary: true,
}

export const LOSING_FORMULA_NAV_ITEM: IStrategicInsightNavigationItem = {
    id: "losing-formula",
    displayName: "Patterns in lost deals",
    isSummary: true,
}

type DealOutcome = "won" | "lost"
interface FormulaChartWrapperProps {
    insightsQueryResult: UseQueryResult<InsightsResponse, Error>
    navItem: IStrategicInsightNavigationItem
    outcomeKey: DealOutcome
    colour: string
    description: string
}

export function WinningFormulaChart(props: {
    insightsQueryResult: UseQueryResult<InsightsResponse, Error>
}) {
    return (
        <FormulaChartWrapper
            insightsQueryResult={props.insightsQueryResult}
            navItem={WINNING_FORMULA_NAV_ITEM}
            outcomeKey="won"
            colour={colors.lime[200]}
            description="The common traits of your won deals. These are the insights most strongly correlated with winning deals."
        />
    )
}

export function LosingFormulaChart(props: {
    insightsQueryResult: UseQueryResult<InsightsResponse, Error>
}) {
    return (
        <FormulaChartWrapper
            insightsQueryResult={props.insightsQueryResult}
            navItem={LOSING_FORMULA_NAV_ITEM}
            outcomeKey="lost"
            colour={colors.fuchsia[200]}
            description="The common traits of your lost deals. These are the insights most strongly correlated with losing deals."
        />
    )
}

/**
 * Wrapper component for <FormulaChart/> that handles loading and no-data states.
 */
function FormulaChartWrapper(props: FormulaChartWrapperProps) {
    const result = props.insightsQueryResult

    if (result.isPending)
        return (
            <div>
                <LoadingPulse rows={1} height="h-80" />
            </div>
        )

    if (!canShowSummaryCharts(result.data)) {
        return null
    }

    return (
        <div
            className="bg-white rounded-lg p-6 border border-gray-200"
            id={props.navItem.id}
        >
            <h2 className="text-2xl font-bold mb-2 text-gray-800">
                {props.navItem.displayName}
            </h2>
            <div className="text-sm text-gray-500 mb-4">
                {props.description}
            </div>
            <FormulaChart
                insights={result.data.insights}
                outcomeKey={props.outcomeKey}
                colour={props.colour}
            />
        </div>
    )
}

function FormulaChart(props: {
    insights: InsightResult[]
    outcomeKey: DealOutcome
    colour: string
}) {
    const [viewMore, setViewMore] = useState(false)
    const defaultNumberToShow = 5
    const maxNumberToShow = 20
    const numberToShow = viewMore ? maxNumberToShow : defaultNumberToShow

    const topInsights = getInsightsWithHighestOutcomeRate(
        props.insights,
        props.outcomeKey
    )

    const insightsToShow = topInsights.slice(0, numberToShow)
    const isTruncated = topInsights.length > defaultNumberToShow

    return (
        <>
            {insightsToShow.length > 0 ? (
                <FormulaTable
                    outcomeKey={props.outcomeKey}
                    colour={props.colour}
                    insights={insightsToShow}
                />
            ) : (
                <div className="text-gray-300 text-center py-4 bg-gray-50 rounded-lg">
                    Not enough data to show
                </div>
            )}
            {isTruncated && (
                <div className="w-full flex justify-center mt-4">
                    <ToggleExpandedViewButton
                        viewAllOptions={viewMore}
                        setViewAllOptions={setViewMore}
                    />
                </div>
            )}
        </>
    )
}

interface InsightOutcome {
    insightId: string
    displayName: string
    value: string
    count: number
    totalCount: number
    percentage: number
}

/**
 * This method finds insights that are most strongly correlated with win/loss rates.
 * For each insight, we calculate what percentage of deals containing that insight were won/lost.
 * For example, if "Pricing Discussion" appears in 100 deals and 80 of those deals were won,
 * it would have an 80% win rate.
 *
 * To avoid misleading results from rare insights, we filter out insights that appear in very few deals
 *
 * Returns: An array of insight outcomes sorted by their win/loss rate (highest first), with low-frequency outliers removed
 */
export function getInsightsWithHighestOutcomeRate(
    insights: InsightResult[],
    outcomeKey: DealOutcome
): InsightOutcome[] {
    const outcomes = insights
        .flatMap((insight) => {
            if (!insight.dealOutcomes?.data) return []

            return insight.dealOutcomes.data.map((outcome) => ({
                insightId: insight.id,
                displayName: insight.displayName,
                value: outcome.value,
                count: outcome[outcomeKey],
                totalCount: outcome.won + outcome.lost,
                percentage:
                    (outcome[outcomeKey] / (outcome.won + outcome.lost)) * 100,
            }))
        })
        .filter((formula) => !isNaN(formula.percentage))

    // We want to filter out any results where the count is less than 10% of the max count
    // This is to avoid insights mentioned few times showing as having a high outcome rate
    const maxCount = Math.max(...outcomes.map((outcome) => outcome.count))
    const countThreshold = Math.max(maxCount * 0.1, 1)

    return _.chain(outcomes)
        .filter((formula) => formula.count > countThreshold)
        .orderBy(["percentage", "count"], ["desc", "desc"])
        .value()
}

function FormulaTable(props: {
    outcomeKey: DealOutcome
    colour: string
    insights: InsightOutcome[]
}) {
    return (
        <div className="w-full overflow-x-auto">
            {/* Header */}
            <div className="grid grid-cols-[minmax(min-content,1fr)_minmax(min-content,1fr)_200px_120px] text-left text-gray-500 text-sm font-bold mb-1">
                <div className="pb-2">Insight</div>
                <div className="pb-2">Answer</div>
                <div className="pb-2 text-center">
                    {props.outcomeKey === "won" ? "Wins" : "Losses"} / Total
                    Deals
                </div>
                <div className="pb-2 text-right">
                    Insight {props.outcomeKey === "won" ? "Win" : "Loss"}%
                </div>
            </div>

            {/* Rows */}
            <div className="grid gap-y-1">
                {props.insights.map((insight) => (
                    <div
                        key={insight.insightId + "-" + insight.value}
                        className="relative text-sm hover:cursor-pointer group"
                        onClick={() => scrollToInsight(insight.insightId)}
                    >
                        <div className="grid grid-cols-[minmax(min-content,1fr)_minmax(min-content,1fr)_200px_120px] relative z-10 items-center">
                            <div className="p-2 pr-4">
                                {insight.displayName}
                            </div>
                            <div className="p-2 pr-4">{insight.value}</div>
                            <div className="p-2 text-center text-gray-900">
                                {insight.count} / {insight.totalCount}
                            </div>
                            <div className="p-2 text-right text-gray-900">
                                {Math.round(insight.percentage)}%
                            </div>
                        </div>
                        <div
                            className="absolute inset-0 z-0 rounded group-hover:opacity-75 transition-opacity"
                            style={{
                                width: `${insight.percentage}%`,
                                backgroundColor: props.colour,
                                left: props.outcomeKey === "won" ? 0 : "auto",
                                right: props.outcomeKey === "lost" ? 0 : "auto",
                            }}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export function canShowSummaryCharts(
    data: InsightsResponse | undefined
): data is InsightsResponse {
    return data !== undefined && data.insights && data.insights.length > 1
}
