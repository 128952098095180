import Select from "react-select"
import { DEAL_CLOSE_DATE_RANGE } from "./utils/dealCloseDate"
import { MultiSelectFilter } from "../MultiSelectFilter"
import { ICrmDealStage, ICrmUser } from "../crm/types/Crm"
import ToggleButton from "../common/ToggleButton"
import colors from "tailwindcss/colors"
import { SecondaryButton } from "../common/Buttons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSliders } from "@fortawesome/free-solid-svg-icons"
import { useEffect, useRef, useState } from "react"
import { annotationTypes } from "../../utils/getAnnotationSections"
import clsx from "clsx"

const CLOSE_DATE_OPTIONS = [
    {
        label: "Now",
        options: [
            {
                value: DEAL_CLOSE_DATE_RANGE.ThisMonth,
                label: "Closing this month",
            },
            {
                value: DEAL_CLOSE_DATE_RANGE.ThisQuarter,
                label: "Closing this quarter",
            },
            {
                value: DEAL_CLOSE_DATE_RANGE.ThisYear,
                label: "Closing this year",
            },
        ],
    },
    {
        label: "Next",
        options: [
            {
                value: DEAL_CLOSE_DATE_RANGE.NextMonth,
                label: "Closing next month",
            },
            {
                value: DEAL_CLOSE_DATE_RANGE.NextQuarter,
                label: "Closing next quarter",
            },
            {
                value: DEAL_CLOSE_DATE_RANGE.NextYear,
                label: "Closing next year",
            },
        ],
    },
    {
        label: "Last",
        options: [
            {
                value: DEAL_CLOSE_DATE_RANGE.LastMonth,
                label: "Closed last month",
            },
            {
                value: DEAL_CLOSE_DATE_RANGE.LastQuarter,
                label: "Closed last quarter",
            },
            {
                value: DEAL_CLOSE_DATE_RANGE.LastYear,
                label: "Closed last year",
            },
        ],
    },
]

interface DisplaySettingsProps {
    groupByStage: boolean
    setGroupByStage: (groupByStage: boolean) => void
    includeTeams: boolean
    setIncludeTeams: (includeTeams: boolean) => void
    hideNoCalls: boolean
    setHideNoCalls: (hideNoCalls: boolean) => void
}

interface DealTableFiltersProps {
    allCrmUsers: ICrmUser[]
    ownerIds: string[]
    ownerTeams: Record<string, string[]>
    setOwnerIds: (ownerIds: string[]) => void
    closeDate: DEAL_CLOSE_DATE_RANGE
    setCloseDate: (range: DEAL_CLOSE_DATE_RANGE) => void
    allDealStages: ICrmDealStage[]
    selectedStages: string[]
    setSelectedStages: (stages: string[]) => void
    annotationTags: string[]
    annotationFilters: string[]
    setAnnotationFilters: (annotationFilters: string[]) => void
}

export function DealTableFilters(
    props: DealTableFiltersProps & DisplaySettingsProps
) {
    const closeDateValue = CLOSE_DATE_OPTIONS.flatMap(
        (group) => group.options
    ).find((option) => option.value === props.closeDate)

    const ownerOptions =
        props.allCrmUsers?.map((user) => ({
            value: user.crm_id,
            label: user.name,
            counter: props.includeTeams
                ? props.ownerTeams[user.crm_id]?.length
                : undefined,
        })) || []
    ownerOptions.sort((a, b) => a.label.localeCompare(b.label)) // sort by name
    const selectedOwners = props.ownerIds.map((id) => ({
        value: id,
        label: props.allCrmUsers?.find((u) => u.crm_id === id)?.name || "",
    }))

    const stageOptions =
        Array.from(
            new Set(props.allDealStages?.map((stage) => stage.name))
        )?.map((name) => ({
            value: name,
            label: name,
        })) || []
    const selectedStages = props.selectedStages.map((stageName) => ({
        value: stageName,
        label: stageName,
    }))

    const defaultValue = CLOSE_DATE_OPTIONS.flatMap(
        (group) => group.options
    ).find((option) => option.value === DEAL_CLOSE_DATE_RANGE.ThisMonth)

    return (
        <div className="flex flex-wrap items-center gap-2">
            <Select
                defaultValue={defaultValue}
                value={closeDateValue}
                options={CLOSE_DATE_OPTIONS}
                placeholder="Filter by closing date"
                className="w-64 text-base"
                onChange={(selectedOption) => {
                    if (selectedOption) props.setCloseDate(selectedOption.value)
                }}
                styles={{
                    menuList: (provided) => ({
                        ...provided,
                        maxHeight: "none", // Remove max-height to display the full dropdown
                    }),
                    control: (provided) => ({
                        ...provided,
                        borderColor: colors.gray[300],
                        borderRadius: "0.5rem",
                    }),
                }}
            />
            <MultiSelectFilter
                title="owners"
                icon="👤"
                options={ownerOptions}
                value={selectedOwners}
                onApply={(selectedOption) => {
                    if (selectedOption) {
                        const selectedOwnerIds = selectedOption.map(
                            (option) => option.value
                        )
                        props.setOwnerIds(
                            selectedOwnerIds.filter((id) => id !== undefined)
                        )
                    }
                }}
            />
            <MultiSelectFilter
                title="stages"
                icon="🎯"
                options={stageOptions}
                value={selectedStages}
                onApply={(selectedOption) => {
                    if (selectedOption) {
                        props.setSelectedStages(
                            selectedOption.map((option) => option.value)
                        )
                    }
                }}
            />
            <AnnotationFilters
                annotationTags={props.annotationTags}
                annotationFilters={props.annotationFilters}
                setAnnotationFilters={props.setAnnotationFilters}
            />
            <DisplaySettings {...props} />
        </div>
    )
}

function AnnotationFilters(props: {
    annotationTags: string[]
    annotationFilters: string[]
    setAnnotationFilters: (annotationFilters: string[]) => void
}) {
    const options = annotationTypes.filter((tag) =>
        props.annotationTags.includes(tag.tag)
    )

    // Append "summary" option
    options.push({
        tag: "summary",
        label: "Summary",
        acronym: "S",
        emoji: "📋",
    })

    function toggleAnnotationFilter(clickedTag: string) {
        const prev = props.annotationFilters
        props.setAnnotationFilters(
            prev.includes(clickedTag)
                ? prev.filter((tag) => tag !== clickedTag)
                : [...prev, clickedTag]
        )
    }

    return (
        <div className="flex gap-2">
            <div className="h-10 flex items-center border border-gray-300 rounded-lg divide-x divide-gray-300 font-semibold overflow-auto">
                {options.map((option) => {
                    const isSelected = props.annotationFilters.includes(
                        option.tag
                    )

                    const tooltip = `${option.emoji ?? ""} ${
                        isSelected ? "Hide" : "Display"
                    } ${option.label}`

                    return (
                        <button
                            key={option.tag}
                            className={clsx(
                                "h-full px-2 hover:bg-gray-100 transition-colors",
                                isSelected ? "bg-gray-200" : "bg-white"
                            )}
                            onClick={() => {
                                toggleAnnotationFilter(option.tag)
                            }}
                            data-tooltip-id="tooltip-explanation"
                            data-tooltip-content={tooltip}
                        >
                            {option.acronym || option.label}
                        </button>
                    )
                })}
            </div>
        </div>
    )
}

function DisplaySettings(props: DisplaySettingsProps) {
    const [showPopup, setShowPopup] = useState(false)
    const popupRef = useRef<HTMLDivElement>(null)

    const handleButtonClick = () => {
        setShowPopup((prev) => !prev)
    }

    // Close the popup if the user clicks outside of it
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                popupRef.current &&
                !popupRef.current.contains(event.target as Node)
            ) {
                setShowPopup(false)
            }
        }
        if (showPopup) {
            document.addEventListener("mousedown", handleClickOutside, {
                // We need to run in the capture phase as the clear emails X
                // gets detached after updating the state (disappears from the
                // CreatableSelect component)
                capture: true,
            })
        } else {
            document.removeEventListener("mousedown", handleClickOutside)
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [showPopup, popupRef])

    return (
        <div className="flex flex-col h-10" ref={popupRef}>
            <SecondaryButton
                className="h-full flex flex-row justify-center items-center text-sm text-gray-600 hover:text-gray-800 gap-2"
                onClick={handleButtonClick}
            >
                <FontAwesomeIcon icon={faSliders} className="w-4 h-4" />
                <span className="hidden md:block">Display</span>
            </SecondaryButton>

            {showPopup && (
                <div className="md:relative mt-1">
                    <DisplaySettingPopup {...props} />
                </div>
            )}
        </div>
    )
}

function DisplaySettingPopup(props: DisplaySettingsProps) {
    return (
        <div className="absolute flex flex-col z-10 text-base min-w-max p-3 bg-white border rounded-lg shadow-lg gap-2 justify-start">
            <div className="flex gap-2 items-center px-2">
                <ToggleButton
                    checked={props.groupByStage}
                    onChange={props.setGroupByStage}
                />
                <span>Group by stage</span>
            </div>
            <div
                className="flex gap-2 items-center px-2"
                data-tooltip-id="tooltip-explanation"
                data-tooltip-content="Include deals owned by any team member in the selected list of owners"
            >
                <ToggleButton
                    checked={props.includeTeams}
                    onChange={props.setIncludeTeams}
                />
                <span>Include team</span>
            </div>
            <div className="flex gap-2 items-center px-2">
                <ToggleButton
                    checked={props.hideNoCalls}
                    onChange={props.setHideNoCalls}
                />
                <span>Hide deals with no calls</span>
            </div>
        </div>
    )
}
