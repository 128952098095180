import _ from "lodash"
import { useMemo, useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { ICRMDeal, ICrmDealUpdate } from "../../types/Deal"
import LoadingSpinner from "../common/LoadingSpinner"
import { DealsDetailsTable } from "./DealDetailsTable"
import axios from "axios"
import { ErrorPage } from "../common/errorPage"
import { DealTableFilters } from "./DealTableFilters"
import { DEAL_CLOSE_DATE_RANGE, getTimeInterval } from "./utils/dealCloseDate"
import { getCacheValue, setCacheValue } from "../../utils/localStorageCache"
import { Interval } from "luxon"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { HashLink } from "react-router-hash-link"
import { SecondaryButton } from "../common/Buttons"
import { useSearchParam } from "../common/hooks/useSearchParam"
import { getHeapInstance } from "../../utils/heap"
import { CrmType, ICrmUser } from "../crm/types/Crm"
import { useCrmIntegration } from "../../hooks/useIntegrations"
import { IOrganizationUser } from "../../types/Organization"
import { queries } from "../../api/queries"
import { NotificationType } from "../common/Notifcations"
import { useNotification } from "../../providers/NotificationProvider"
import { parseAxiosError } from "../../utils/parseAxiosError"

const CLOSE_DATE_FILTER_CACHE_KEY = "deal-table-close-date"
const OWNER_IDS_FILTER_CACHE_KEY = "deal-table-owner-ids"
const STAGE_IDS_FILTER_CACHE_KEY = "deal-table-stage-ids"
const GROUP_BY_STAGE_CACHE_KEY = "deal-table-group-by-stage"
const INCLUDE_TEAMS_CACHE_KEY = "deal-table-include-teams"
const HIDE_NO_CALLS_CACHE_KEY = "deal-table-hide-no-calls"
const ANNOTATION_FILTERS_CACHE_KEY = "deal-table-annotation-filters"

const SUPPORTED_CRMS = [CrmType.Hubspot, CrmType.Salesforce]

export function DealsList() {
    const { crmType } = useCrmIntegration()
    const hasUnsupportedCrm =
        crmType !== undefined && !SUPPORTED_CRMS.includes(crmType)
    const queryClient = useQueryClient()
    const { addNotification } = useNotification()

    const { data: orgUsers } = useQuery(queries.users.list())
    const { data: crmUsers } = useQuery(queries.crm.users())
    const { data: dealStages } = useQuery(queries.crm.dealStages())

    const { data: annotationTags } = useQuery<string[]>({
        queryKey: ["organization/annotation-tags/company"],
        queryFn: async () => {
            const { data } = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/organization/annotation-tags/company`
            )
            return data
        },
    })

    const ownerTeams = useMemo(
        () => getOwnerTeams(orgUsers, crmUsers),
        [orgUsers, crmUsers]
    )

    const [closeDateFilter, setCloseDateFilter] =
        useState<DEAL_CLOSE_DATE_RANGE>(
            getCacheValue(
                CLOSE_DATE_FILTER_CACHE_KEY,
                DEAL_CLOSE_DATE_RANGE.ThisMonth
            )
        )
    const [ownerIds, setOwnerIds] = useState<string[]>(
        getCacheValue(OWNER_IDS_FILTER_CACHE_KEY, [])
    )
    const [filterTerm, setFilterTerm] = useSearchParam("filter")

    const [stages, setStages] = useState<string[]>(
        getCacheValue(STAGE_IDS_FILTER_CACHE_KEY, [])
    )

    const closeDateInterval = useMemo(
        () => getTimeInterval(closeDateFilter),
        [closeDateFilter]
    )

    const [groupByStage, setGroupByStage] = useState<boolean>(
        getCacheValue(GROUP_BY_STAGE_CACHE_KEY, true)
    )

    const [includeTeams, setIncludeTeams] = useState<boolean>(
        getCacheValue(INCLUDE_TEAMS_CACHE_KEY, false)
    )

    const [hideNoCalls, setHideNoCalls] = useState<boolean>(
        getCacheValue(HIDE_NO_CALLS_CACHE_KEY, false)
    )

    const [annotationFilters, setAnnotationFilters] = useState<string[]>(
        getCacheValue(ANNOTATION_FILTERS_CACHE_KEY, [
            "summary",
            "obstacles",
            "meddpicc_paper_process",
        ])
    )

    const queryKey = useMemo(
        () => ["deals", closeDateInterval.toISO(), ownerIds, includeTeams],
        [closeDateInterval, ownerIds, includeTeams]
    )

    const {
        data: deals,
        isPending,
        isError,
        error,
    } = useQuery<ICRMDeal[]>({
        queryKey,
        queryFn: async () => {
            let selectedOwnerIds = ownerIds
            if (includeTeams) {
                selectedOwnerIds = _.uniq([
                    ...selectedOwnerIds,
                    ...ownerIds.flatMap((ownerId) => ownerTeams[ownerId] ?? []),
                ])
            }
            return await fetchDeals(closeDateInterval, selectedOwnerIds)
        },
        staleTime: 5 * 60 * 1000, // Only consider data stale & refetch after 5 minutes
    })

    const updateDealData = (updatedDeal: ICrmDealUpdate) => {
        queryClient.setQueryData(queryKey, (oldDealListData: ICRMDeal[]) => {
            if (!oldDealListData) {
                return [updatedDeal]
            }
            return oldDealListData.map((deal) => {
                if (deal.id === updatedDeal.id) {
                    // We're partially updating the deal, so we need to merge
                    return { ...deal, ...updatedDeal }
                }
                return deal
            })
        })
    }

    const updateDealMutation = useMutation({
        // We want to update the deal in the list but require the id to do so!
        mutationFn: async (dealUpdate: ICrmDealUpdate) => {
            const response = await axios.patch(
                `${process.env.REACT_APP_API_DOMAIN}/deals/crm/${dealUpdate.id}`,
                dealUpdate
            )
            getHeapInstance()?.track("deal-value-updated", {
                dealId: dealUpdate.id,
            })
            return response.data as ICRMDeal
        },
        onSuccess: (updatedDeal: ICRMDeal) => {
            // Update the deal list with the updated deal
            updateDealData(updatedDeal)

            addNotification(
                "Deal updated",
                `Deal ${updatedDeal.name} updated successfully`,
                NotificationType.Success
            )
        },
        onError: (error) => {
            let errorMessage =
                parseAxiosError(error) ||
                "An error occurred while updating the deal."
            addNotification(
                "Failed to update deal",
                errorMessage,
                NotificationType.Error
            )
            console.error("Error updating deal:", error)
        },
    })

    useEffect(() => {
        document.title = "Deals - " + process.env.REACT_APP_DOCUMENT_TITLE

        return () => {
            // Reset the title when the component unmounts
            document.title = process.env.REACT_APP_DOCUMENT_TITLE!
        }
    })

    const filteredDeals = useMemo(() => {
        if (!deals) return deals

        const hasCalls = (deal: ICRMDeal) =>
            !hideNoCalls || !!deal.company?.last_call_time

        const isSelectedStage = (deal: ICRMDeal) =>
            stages.length === 0 ||
            (deal.stage && stages.includes(deal.stage.name))

        return deals.filter((deal) => hasCalls(deal) && isSelectedStage(deal))
    }, [deals, stages, hideNoCalls])

    if (hasUnsupportedCrm) {
        return <UnSupportedCRM />
    }

    if (isError) {
        if (axios.isAxiosError(error) && error.response?.status === 412) {
            return MissingCRMIntegration()
        } else {
            return <ErrorPage error={{ message: "Failed to load deals" }} />
        }
    }

    return (
        <section className="my-6 px-2 py-6 space-y-4">
            <div className="flex flex-col md:flex-row gap-4 px-6 w-full">
                <DealTableFilters
                    allCrmUsers={crmUsers || []}
                    allDealStages={dealStages || []}
                    closeDate={closeDateFilter}
                    setCloseDate={(range) => {
                        setCloseDateFilter(range)
                        setCacheValue(CLOSE_DATE_FILTER_CACHE_KEY, range)
                    }}
                    ownerTeams={ownerTeams}
                    ownerIds={ownerIds}
                    setOwnerIds={(ids) => {
                        setOwnerIds(ids)
                        setCacheValue(OWNER_IDS_FILTER_CACHE_KEY, ids)
                    }}
                    selectedStages={stages}
                    setSelectedStages={(names) => {
                        setStages(names)
                        setCacheValue(STAGE_IDS_FILTER_CACHE_KEY, names)
                    }}
                    groupByStage={groupByStage}
                    setGroupByStage={(groupByStage) => {
                        setGroupByStage(groupByStage)
                        setCacheValue(GROUP_BY_STAGE_CACHE_KEY, groupByStage)
                        getHeapInstance()?.track("deals-group-by-stage", {
                            groupByStage,
                        })
                    }}
                    includeTeams={includeTeams}
                    setIncludeTeams={(includeTeams) => {
                        setIncludeTeams(includeTeams)
                        setCacheValue(INCLUDE_TEAMS_CACHE_KEY, includeTeams)
                    }}
                    hideNoCalls={hideNoCalls}
                    setHideNoCalls={(hideNoCalls) => {
                        setHideNoCalls(hideNoCalls)
                        setCacheValue(HIDE_NO_CALLS_CACHE_KEY, hideNoCalls)
                    }}
                    annotationTags={annotationTags || []}
                    annotationFilters={annotationFilters}
                    setAnnotationFilters={(annotationFilters) => {
                        setAnnotationFilters(annotationFilters)
                        setCacheValue(
                            ANNOTATION_FILTERS_CACHE_KEY,
                            annotationFilters
                        )
                        getHeapInstance()?.track("deals-annotation-filters", {
                            annotationFilters,
                        })
                    }}
                />
                <div className="flex flex-grow justify-end">
                    <Filter
                        filterTerm={filterTerm}
                        setFilterTerm={setFilterTerm}
                    />
                </div>
            </div>
            <div className="space-y-4">
                {isPending || filteredDeals === undefined ? (
                    <LoadingSpinner />
                ) : (
                    <DealsDetailsTable
                        deals={filteredDeals}
                        dealStages={dealStages || []}
                        filterTerm={filterTerm}
                        groupByStage={groupByStage}
                        annotationTags={annotationTags || []}
                        annotationFilters={annotationFilters}
                        updateDeal={updateDealMutation.mutateAsync}
                    />
                )}
            </div>
        </section>
    )
}

function Filter(props: {
    filterTerm: string
    setFilterTerm: (filterTerm: string) => void
}) {
    return (
        <div className="h-10 flex flex-row gap-2 w-full md:w-72 max-w-72 text-base bg-white items-center py-1 pl-4 border border-gray-300 border-block rounded-lg">
            <FontAwesomeIcon icon={faSearch} className="text-gray-500" />
            <input
                autoFocus
                className="w-full outline-none placeholder:text-gray-600"
                type="text"
                placeholder="Filter by name"
                value={props.filterTerm}
                onChange={(e) => props.setFilterTerm(e.target.value)}
            />
        </div>
    )
}

export async function fetchDeals(
    closeDateInterval: Interval,
    ownerIds: string[],
    isClosed?: boolean
) {
    const start = closeDateInterval.start!.toUTC().toISO()!
    const end = closeDateInterval.end!.toUTC().toISO()!

    const params = new URLSearchParams({
        close_date_after: start,
        close_date_before: end,
    })
    if (isClosed !== undefined) {
        params.append("is_closed", isClosed.toString())
    }
    ownerIds.forEach((id) => params.append("owner_ids", id))

    const response = await axios.get(
        `${process.env.REACT_APP_API_DOMAIN}/deals/crm`,
        {
            params,
        }
    )
    return response.data
}

function MissingCRMIntegration() {
    return (
        <span className="h-full my-auto flex flex-col space-y-4 justify-center items-center">
            <span className="text-base text-gray-600">
                Connect Glyphic to your CRM to see your deals
            </span>
            <HashLink smooth to="/settings#crm">
                <SecondaryButton>Settings</SecondaryButton>
            </HashLink>
        </span>
    )
}

function UnSupportedCRM() {
    return (
        <span className="h-full my-auto flex flex-col space-y-4 justify-center items-center">
            <span className="text-base text-gray-600">
                This feature requires a Hubspot or Salesforce integration.
            </span>
        </span>
    )
}

/**
 * Returns a map of owner ids to a list of owner ids in the team (direct
 * reports).
 */
function getOwnerTeams(
    orgUsers: IOrganizationUser[] | undefined,
    crmUsers: ICrmUser[] | undefined
): Record<string, string[]> {
    // This function requires a few steps unfortunately!
    // We have to go from a hierarchy based on glyphic ids to a map based on
    // crm ids.
    // This requires mapping users by email.

    // 1. Create a helper map from email to crm id.
    const emailToCrmId = _(crmUsers).keyBy("email").mapValues("crm_id").value()

    // 2. Create a helper map from glyphic id to crm id.
    const glyphicToCrmId = _(orgUsers)
        .keyBy("id")
        .mapValues((user) => {
            return emailToCrmId[user.email]
        })
        .pickBy(_.identity) // Remove undefined values
        .value()

    // 3. Group org users by manager id.
    return (
        _(orgUsers)
            .groupBy("manager_id")
            .mapValues((users) =>
                // 4. Map the grouped users to their crm ids.
                // Ignore users that don't have a crm id
                users.map((user) => glyphicToCrmId[user.id]).filter(_.identity)
            )
            // 5. Convert the manager ids to crm ids.
            // Convert the manager ids to crm ids
            .mapKeys(
                (users, managerId) => managerId && glyphicToCrmId[managerId]
            )
            .value()
    )
}
