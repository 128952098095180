import clsx from "clsx"

export default function LoadingMessage({
    message,
    textColor,
}: {
    message?: string
    textColor?: string
}) {
    const textStyle = "text-" + (textColor ?? "gray-500")
    return (
        <>
            <style>
                {`@keyframes ellipsis {
                        0% {
                            content: ".";
                        }
                        33% {
                            content: "..";
                        }
                        66% {
                            content: "...";
                        }
                        100% {
                            content: ".";
                        }
                    }

                    .ellipsis::after {
                        min-width: 1em;
                        content: "...";
                        display: inline-block;
                        animation: ellipsis 2s infinite;
                    }`}
            </style>
            <p className={clsx(textStyle, "ellipsis")}>{message ?? ""}</p>
        </>
    )
}
