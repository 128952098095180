import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export function ToggleExpandedViewButton({
    viewAllOptions,
    setViewAllOptions,
}: {
    viewAllOptions: boolean
    setViewAllOptions: (value: boolean) => void
}) {
    return (
        <button
            className="items-center flex group gap-1 text-gray-700"
            onClick={() => setViewAllOptions(!viewAllOptions)}
        >
            <span className="mr-1">
                {viewAllOptions ? "View less" : "View all"}
            </span>
            <FontAwesomeIcon
                icon={faChevronDown}
                className={`transition-transform duration-300 ease-in-out ${
                    viewAllOptions ? "rotate-180" : ""
                }`}
            />
        </button>
    )
}
