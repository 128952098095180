import { faLinkedin } from "@fortawesome/free-brands-svg-icons"
import {
    ICompanyAddress,
    ICompanyInfo,
    ICompanyInfoSources,
} from "../../types/CompanyProfile"
import { useState } from "react"
import { TabHead } from "../common/Tabs"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGlobe } from "@fortawesome/free-solid-svg-icons"
import { crmIconFromUrl } from "../crm/utils/crmIconFromUrl"
import { CrunchbaseLogo } from "../../assets/CrunchbaseLogo"
import { LogoLink } from "../common/LogoLink"
import { getCacheValue, setCacheValue } from "../../utils/localStorageCache"

enum Tabs {
    Crm = "CRM",
    Crunchbase = "Crunchbase",
}
const TAB_CACHE_KEY = "company-info-source-tab-index"

export function CompanyInfoSources(props: {
    companyInfo: ICompanyInfoSources | undefined
}) {
    const tabs = [
        { type: Tabs.Crunchbase, label: Tabs.Crunchbase.toString() },
        { type: Tabs.Crm, label: Tabs.Crm.toString() },
    ]
    const [activeTab, setActiveTab] = useState(getDefaultTab(props.companyInfo))

    if (!props.companyInfo) {
        return <div className="text-gray-600 text-sm">No info to show</div>
    }

    return (
        <div>
            <div className="w-fit mb-2">
                <TabHead
                    tabs={tabs}
                    tabStyle="secondary"
                    bordered={false}
                    activeTab={activeTab}
                    onTabChange={(index: number) => {
                        setActiveTab(index)
                        setCacheValue(TAB_CACHE_KEY, index)
                    }}
                />
            </div>
            {tabs[activeTab].type === Tabs.Crunchbase && (
                <CompanyInfo
                    info={props.companyInfo.crunchbase}
                    dataSource="Crunchbase"
                />
            )}

            {tabs[activeTab].type === Tabs.Crm && (
                <CompanyInfo
                    info={props.companyInfo.crm}
                    dataSource="your CRM"
                />
            )}
        </div>
    )
}

function getDefaultTab(companyInfo: ICompanyInfoSources | undefined): number {
    // If only one of Crunchbase / CRM is available, show that
    // Otherwise default to latest selected
    if (companyInfo?.crunchbase && !companyInfo?.crm) {
        return 0 // Crunchbase
    } else if (!companyInfo?.crunchbase && companyInfo?.crm) {
        return 1 // CRM
    }
    return getCacheValue(TAB_CACHE_KEY, 0)
}

function CompanyInfo(props: { info: ICompanyInfo | null; dataSource: string }) {
    const info = props.info
    if (info == null) {
        return (
            <div className="text-gray-600 text-sm">
                No info to show from {props.dataSource}
            </div>
        )
    }

    return (
        <div className="space-y-3">
            <div className="flex flex-row justify-between items-end">
                <div className="flex flex-row items-center gap-2">
                    {info.image_url && (
                        <img
                            className="w-12 h-12 rounded-lg object-cover"
                            src={info.image_url}
                            alt="Company logo"
                        />
                    )}
                    <div>
                        <div className="font-bold text-lg">{info.name}</div>
                        <div className="flex items-center justify-between">
                            {info.founded_year && (
                                <div className="text-gray-600">
                                    Founded {info.founded_year}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <CompanyLinks info={info} />
            </div>
            <CompanyField heading="Description" value={info.description} />
            <div className="grid grid-cols-2 gap-x-10 gap-y-3 overflow-auto">
                <CompanyField
                    heading="Headquarters"
                    value={formatAddress(info.address)}
                />
                <CompanyField
                    heading="Industries"
                    value={info.industries?.join(", ") || null}
                />
                <CompanyField heading="Headcount" value={info.num_employees} />
                <CompanyField
                    heading="Company status"
                    value={info.ipo_status}
                />
                <CompanyField
                    heading="Annual revenue"
                    value={info.annual_revenue_usd}
                />
                <CompanyField
                    heading="Last Funding Type"
                    value={info.last_funding_type}
                />
                <CompanyField
                    heading="Total money raised"
                    value={formatMonetaryAmount(info.total_funding_usd, "USD")}
                />
            </div>
        </div>
    )
}

function CompanyLinks(props: { info: ICompanyInfo }) {
    return (
        <div className="flex items-center space-x-2">
            {props.info.crunchbase_url && (
                <LogoLink
                    url={props.info.crunchbase_url}
                    logo={<CrunchbaseLogo className="w-5 h-auto" />}
                />
            )}
            {props.info.crm_url && (
                <LogoLink
                    url={props.info.crm_url}
                    logo={
                        <FontAwesomeIcon
                            icon={crmIconFromUrl(props.info.crm_url)}
                            size="lg"
                        />
                    }
                />
            )}
            {props.info.website_url && (
                <LogoLink
                    url={props.info.website_url}
                    logo={<FontAwesomeIcon icon={faGlobe} size="lg" />}
                />
            )}
            {props.info.linkedin_url && (
                <LogoLink
                    url={props.info.linkedin_url}
                    logo={<FontAwesomeIcon icon={faLinkedin} size="lg" />}
                />
            )}
        </div>
    )
}

function CompanyField(props: {
    heading: string
    value: string | number | null
}) {
    if (props.value == null) {
        return <></>
    }
    return (
        <div>
            <div className="font-bold text-sm">{props.heading}</div>
            <div className="text-gray-600 text-sm first-letter:capitalize">
                {props.value}
            </div>
        </div>
    )
}

function formatAddress(address: ICompanyAddress): string | null {
    const formatted = [address.city, address.country].filter(Boolean).join(", ")
    if (!formatted) {
        return null
    }
    return formatted
}

function formatMonetaryAmount(
    value: number | null,
    currency: string
): string | null {
    if (!value) {
        return null
    }
    return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currency,
        notation: "compact",
        compactDisplay: "short",
    }).format(value)
}
