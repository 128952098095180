import { TabHead } from "../common/Tabs"
import { useEffect, useState } from "react"
import { CompanyInfoSources } from "./CompanyInfo"
import { CompanyNews } from "../company-view/CompanyNews"
import { ICompanyProfile } from "../../types/CompanyProfile"
import axios from "axios"
import { LoadingPulse } from "../common/LoadingPulse"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../common/Notifcations"
import { SimpleCard } from "../common/SimpleCard"

enum Tabs {
    Info = "Company Info",
    News = "News",
}

export function CompanyCard(props: { companyDomain: string }) {
    const { addNotification } = useNotification()
    const [companyProfile, setCompanyProfile] =
        useState<ICompanyProfile | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {
        async function getCompanyProfile() {
            try {
                setIsLoading(true)
                const { data } = await axios.get(
                    `${process.env.REACT_APP_API_DOMAIN}/companies/${props.companyDomain}/profile`
                )
                setCompanyProfile(data)
            } catch (error) {
                addNotification(
                    "Failed to load company profile",
                    "",
                    NotificationType.Error
                )
            } finally {
                setIsLoading(false)
            }
        }
        getCompanyProfile()
    }, [props.companyDomain, addNotification])

    const tabs = [
        { type: Tabs.Info, label: Tabs.Info.toString() },
        { type: Tabs.News, label: Tabs.News.toString() },
    ]
    const [activeTab, setActiveTab] = useState(0)

    return (
        <SimpleCard className="p-3">
            <div className="overflow-auto space-y-2">
                <div className="w-fit">
                    <TabHead
                        tabs={tabs}
                        bordered={false}
                        activeTab={activeTab}
                        onTabChange={(index, _) => setActiveTab(index)}
                    />
                </div>
                <div className="overflow-y-scroll scrollbar-hide border-t border-gray-300 py-4">
                    {isLoading ? (
                        <div className="p-4">
                            <LoadingPulse rows={8} />
                        </div>
                    ) : (
                        <>
                            {tabs[activeTab].type === Tabs.Info && (
                                <CompanyInfoSources
                                    companyInfo={companyProfile?.info}
                                />
                            )}
                            {tabs[activeTab].type === Tabs.News && (
                                <CompanyNews
                                    news={companyProfile?.news || []}
                                />
                            )}
                        </>
                    )}
                </div>
            </div>
        </SimpleCard>
    )
}
