import { createQueryKeys } from "@lukemorales/query-key-factory"
import axios from "axios"
import { transformSnakeKeysToCamelCase } from "../../utils/transformSnakeKeysToCamelCase"

export interface Announcement {
    id: string
    title: string
    message: string
    startedAt: string
}

export const announcementQueries = createQueryKeys("announcements", {
    get: () => ({
        queryKey: ["announcements"],
        queryFn: async () => {
            const response = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/announcements`
            )
            return response.data.map(
                transformSnakeKeysToCamelCase
            ) as Announcement[]
        },
    }),
})
