import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import axios from "axios"
import { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPencil, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import { PrimaryButton, SecondaryButton } from "../common/Buttons"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../common/Notifcations"
import LoadingSpinner from "../common/LoadingSpinner"
import { stringToColour } from "../../utils/stringToColour"
import { Card } from "./Card"
import { queries } from "../../api/queries"
import { ICallTag } from "../../types/Call"
import { NavigateLink } from "../common/NavigateLink"

export function CallTags() {
    const { data: tags, isPending } = useQuery(queries.callTags.list())

    if (isPending) return <LoadingSpinner />

    return (
        <Card title="Call Tags">
            <span className="text-gray-500 text-sm">
                Use tags to help organize and filter calls in your organization.
            </span>
            <div className="mt-4 mb-6">
                <AddTagForm />
            </div>
            <div className="border border-gray-300 rounded-lg w-full overflow-auto text-sm">
                <table className="w-full">
                    <thead>
                        <tr className="h-16">
                            <th className="text-left px-4 py-2 w-64">
                                Tag Name
                            </th>
                            <th className="p-2 w-32">Edit</th>
                            <th className="p-2 w-32">Delete</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-300 border-t border-gray-300">
                        {tags?.map((tag) => (
                            <TagRow key={tag.id} tag={tag} />
                        ))}
                    </tbody>
                </table>
            </div>
        </Card>
    )
}

export function Tag(props: { text: string }) {
    const tagColour = stringToColour(props.text)
    const [hovered, setHovered] = useState(false)

    return (
        <NavigateLink href={`/calls?tags=${props.text}`}>
            <div
                className="border rounded-md px-2 py-1 text-sm flex items-center capitalize w-fit"
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                style={{
                    backgroundColor: hovered ? tagColour[100] : tagColour[50],
                    borderColor: tagColour[300],
                }}
            >
                <span
                    className="w-2 h-2 rounded-full mr-1.5"
                    style={{ backgroundColor: tagColour[500] }}
                ></span>
                <span>{props.text}</span>
            </div>
        </NavigateLink>
    )
}

function TagRow({ tag }: { tag: ICallTag }) {
    const [isEditing, setIsEditing] = useState(false)
    const [editedTagName, setEditedTagName] = useState(tag.name)
    const queryClient = useQueryClient()
    const { addNotification } = useNotification()

    const editTag = useMutation({
        mutationFn: async (newName: string) => {
            await axios.put(
                `${process.env.REACT_APP_API_DOMAIN}/call_tags/${tag.id}`,
                { name: newName }
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: queries.callTags.list().queryKey,
            })
            setIsEditing(false)
        },
        onError: (error) => {
            addNotification(
                "Failed to update tag",
                `${error}`,
                NotificationType.Error
            )
        },
    })

    const deleteTag = useMutation({
        mutationFn: async () => {
            await axios.delete(
                `${process.env.REACT_APP_API_DOMAIN}/call_tags/${tag.id}`
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: queries.callTags.list().queryKey,
            })
        },
        onError: (error) => {
            addNotification(
                "Failed to delete tag",
                `${error}`,
                NotificationType.Error
            )
        },
    })

    return (
        <tr>
            <td className="px-4 py-2 w-64">
                {isEditing ? (
                    <input
                        type="text"
                        autoFocus
                        value={editedTagName}
                        onChange={(e) => setEditedTagName(e.target.value)}
                        className="border border-gray-300 rounded px-2 py-1 w-fit min-w-[100px]"
                    />
                ) : (
                    <Tag text={tag.name} />
                )}
            </td>
            <td className="p-2 text-center w-32">
                {isEditing ? (
                    <div className="space-x-2">
                        <PrimaryButton
                            onClick={() => {
                                editTag.mutate(editedTagName)
                            }}
                        >
                            Save
                        </PrimaryButton>
                        <SecondaryButton
                            onClick={() => {
                                setIsEditing(false)
                                setEditedTagName(tag.name)
                            }}
                        >
                            Cancel
                        </SecondaryButton>
                    </div>
                ) : (
                    <SecondaryButton
                        onClick={() => {
                            setIsEditing(true)
                            setEditedTagName(tag.name)
                        }}
                    >
                        <FontAwesomeIcon icon={faPencil} />
                    </SecondaryButton>
                )}
            </td>
            <td className="p-2 text-center w-32">
                <SecondaryButton
                    className="hover:text-red-500"
                    onClick={() => deleteTag.mutate()}
                >
                    <FontAwesomeIcon icon={faTrashCan} />
                </SecondaryButton>
            </td>
        </tr>
    )
}

function AddTagForm() {
    const [newTagName, setNewTagName] = useState("")
    const queryClient = useQueryClient()
    const { addNotification } = useNotification()

    const addTag = useMutation({
        mutationFn: async (tagName: string) => {
            await axios.post(`${process.env.REACT_APP_API_DOMAIN}/call_tags`, {
                name: tagName,
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: queries.callTags.list().queryKey,
            })
            setNewTagName("")
        },
        onError: (error) => {
            addNotification(
                "Failed to add tag",
                `${error}`,
                NotificationType.Error
            )
        },
    })

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault()
                if (newTagName.trim()) {
                    addTag.mutate(newTagName.trim())
                }
            }}
        >
            <div className="flex items-center">
                <input
                    type="text"
                    value={newTagName}
                    onChange={(e) => setNewTagName(e.target.value)}
                    placeholder="Enter new tag name"
                    className="mr-2 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <PrimaryButton type="submit" disabled={!newTagName.trim()}>
                    Add Tag
                </PrimaryButton>
            </div>
        </form>
    )
}
