import { UpcomingCalls } from "./UpcomingCalls"
import { Link } from "react-router-dom"
import { PrimaryButton, SecondaryButton } from "../common/Buttons"
import allCalendars from "../../assets/all_calendars.png"
import { useIntegrations } from "../../hooks/useIntegrations"
import { Modal } from "../common/Modal"
import { useState, useEffect } from "react"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../common/Notifcations"
import { AddBotIcon } from "../../assets/icons/AddBotIcon"
import { sendBotToMeeting } from "../../utils/sendBotToMeeting"
import { useUser } from "../../providers/UserProvider"
import { hasPermission } from "../../utils/Permissions"
import { Permission } from "../../types/Permission"

export function Schedule() {
    const { hasCalendar, isPending } = useIntegrations()

    useEffect(() => {
        document.title = "Schedule - " + process.env.REACT_APP_DOCUMENT_TITLE

        return () => {
            // Reset the title when the component unmounts
            document.title = process.env.REACT_APP_DOCUMENT_TITLE!
        }
    })

    if (isPending) {
        return null
    }

    return (
        <>
            <div className="flex justify-end px-6 pt-4">
                <RecordNewMeetingButton />
            </div>
            <section className="mx-auto max-w-5xl px-6 py-4 first:pt-12">
                {!hasCalendar ? <Empty /> : <UpcomingCalls />}
            </section>
        </>
    )
}

export function Empty() {
    const user = useUser()
    const canConnectCalendar =
        user && hasPermission(user, Permission.RECORD_CALLS)

    const connectCalendarMessage = (
        <>
            <div>Connect your calendar to see your upcoming calls</div>
            <Link to="/settings">
                <PrimaryButton>Calendar Settings</PrimaryButton>
            </Link>
        </>
    )

    const scheduleUnavailableMessage = (
        <div className="flex flex-col space-y-4 items-center justify-center">
            <div>You do not have permission to connect your calendar.</div>
            <div>Contact your Glyphic admin to request access.</div>
        </div>
    )

    return (
        <div className="flex flex-col space-y-4 items-center justify-center pt-20 text-gray-500">
            <img src={allCalendars} alt="All Calendars" className="w-20 h-20" />
            {canConnectCalendar
                ? connectCalendarMessage
                : scheduleUnavailableMessage}
        </div>
    )
}

export function RecordNewMeetingButton() {
    const user = useUser()
    const { addNotification } = useNotification()
    const [loading, setLoading] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const canRecordCalls = user && hasPermission(user, Permission.RECORD_CALLS)

    if (!canRecordCalls) {
        return null
    }

    const handleFormSubmit = async (
        event: React.FormEvent<HTMLFormElement>
    ) => {
        event.preventDefault()
        const formData = new FormData(event.currentTarget)
        const meetingUrl = formData.get("meeting_url") as string
        try {
            setLoading(true)
            await sendBotToMeeting(null, meetingUrl)
            addNotification(
                "🚀 The copilot is on its way to record the meeting. This may take a few seconds.",
                "It may need to be let in by the meeting host.",
                NotificationType.Success
            )
            setIsOpen(false)
        } catch (error) {
            addNotification(
                "The copilot could not be sent to the meeting. Please try again.",
                "",
                NotificationType.Error
            )
        } finally {
            setLoading(false)
        }
    }
    return (
        <>
            <SecondaryButton
                className="flex items-center gap-2"
                onClick={() => setIsOpen(true)}
            >
                <AddBotIcon className="w-4 h-4" />
                Record a new meeting...
            </SecondaryButton>
            <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
                <form
                    onSubmit={handleFormSubmit}
                    className="p-8 space-y-8 w-[800px]"
                >
                    <h2 className="text-xl font-bold">Record a new meeting</h2>
                    <label className="flex flex-col space-y-2">
                        <div className="flex flex-col space-y-0.5">
                            <span className="font-semibold">Meeting link</span>
                            <span className="text-sm text-gray-500">
                                For a Google Meet, Zoom, Microsoft Teams, or
                                Webex meeting
                            </span>
                        </div>
                        <input
                            autoFocus
                            type="text"
                            name="meeting_url"
                            className="border rounded-md p-2 flex-grow w-full focus:outline-none focus:ring-0"
                            placeholder="e.g., https://meet.google.com/ctf-rsb-grd"
                            required
                        />
                    </label>
                    <div className="flex justify-end pt-8">
                        <PrimaryButton
                            type="submit"
                            className="flex items-center gap-2"
                            disabled={loading}
                        >
                            <AddBotIcon className="w-4 h-4" />
                            Send copilot
                        </PrimaryButton>
                    </div>
                </form>
            </Modal>
        </>
    )
}
