import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { IResolvedCrmFieldValue } from "../crm/types/Crm"
import { LoadingPulse } from "../common/LoadingPulse"
import { crmIconFromUrl } from "../crm/utils/crmIconFromUrl"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { LogoLink } from "../common/LogoLink"
import { ReadOnlyFieldList } from "../CustomInsights"

export function CustomInsights(props: {
    companyDomain: string
    companyCrmUrl?: string | null
}) {
    const {
        data: fields,
        isPending,
        isError,
    } = useQuery<IResolvedCrmFieldValue[]>({
        queryKey: [`companies/${props.companyDomain}/crm/fields`],
        queryFn: async () => {
            const { data } = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/companies/${props.companyDomain}/crm/fields`
            )
            return data
        },
        staleTime: 1000 * 60 * 5, // 5 minutes
    })

    if (isError) {
        return (
            <div className="text-gray-600 text-sm">
                Unable to load custom insights
            </div>
        )
    }

    if (fields && fields.length === 0) {
        return (
            <div className="text-gray-600 text-sm">
                No custom insights available
            </div>
        )
    }

    return (
        <div className="bg-white border rounded-lg p-3 text-base space-y-4">
            <div className="flex items-center space-x-2">
                {props.companyCrmUrl && (
                    <LogoLink
                        url={props.companyCrmUrl}
                        logo={
                            <FontAwesomeIcon
                                icon={crmIconFromUrl(props.companyCrmUrl)}
                                size="lg"
                            />
                        }
                    />
                )}
                <h2 className="font-bold">Company custom insights</h2>
            </div>
            <div>
                {isPending ? (
                    <LoadingPulse rows={5} />
                ) : (
                    <ReadOnlyFieldList fields={fields} />
                )}
            </div>
        </div>
    )
}
