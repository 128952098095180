export interface IStrategicInsightNavigationItem {
    id: string
    displayName: string
    isSummary: boolean // Whether this item is displayed in the summary section
}

export function scrollToInsight(insightId: string) {
    const element = document.getElementById(insightId)
    if (element) {
        element.scrollIntoView({ behavior: "smooth" })
    }
    window.location.hash = insightId
}
