import { useQuery } from "@tanstack/react-query"
import { ICall, INotes } from "../../types/Call"
import { SyncNotesToCrmButton } from "../crm/SyncNotesToCrmButton"
import { ShareCallButton } from "./ShareCallButton"
import axios from "axios"
import { DeleteCallButton } from "./DeleteCallButton"
import { CopyIcon } from "../../assets/icons/CopyIcon"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../common/Notifcations"
import { TogglePrivacyButton } from "./TogglePrivacyButton"
import { getHeapInstance } from "../../utils/heap"

export function CallOptions(props: { call: ICall }) {
    const { data: notes } = useQuery<INotes>({
        queryKey: [props.call.id, "notes"],
        queryFn: async () => {
            const { data } = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/calls/${props.call.id}/notes`
            )
            return data
        },
    })
    const isCompleted = props.call.status === "completed"
    const notesOrEmpty = notes?.text ?? ""

    if (!props.call.can_view) {
        return null
    }

    return (
        <div className="flex flex-row items-center gap-6">
            <ShareCallButton call={props.call} />
            {isCompleted && (
                <>
                    <CopyNotesButton notes={notesOrEmpty} />
                    <SyncNotesToCrmButton
                        callId={props.call.id}
                        notes={notesOrEmpty}
                    />
                </>
            )}
            <TogglePrivacyButton call={props.call} />
            <DeleteCallButton callId={props.call.id} />
        </div>
    )
}

function CopyNotesButton(props: { notes: string }) {
    const { addNotification } = useNotification()

    const copy = async () => {
        getHeapInstance()?.track("call-copy-notes-clicked")
        if (!props.notes) {
            addNotification("Failed to copy notes", "", NotificationType.Error)
            return
        }
        try {
            await navigator.clipboard.writeText(props.notes)
            addNotification("notes copied", "", NotificationType.Success)
        } catch (error) {
            console.error("Failed to copy notes:", error)
        }
    }

    return (
        <button
            onClick={copy}
            className="flex flex-col items-center text-sm text-gray-600 hover:text-gray-800"
        >
            <CopyIcon className="w-4 h-4" />
            <span className="hidden md:block">Copy notes</span>
        </button>
    )
}
