import React, { useRef, useCallback, useState, useEffect, useMemo } from "react"
import { PriorityButton } from "../common/Buttons"
import { Logo } from "../../assets/Logo"
import { SimpleCard } from "../common/SimpleCard"
import { queries } from "../../api/queries"
import { useQuery } from "@tanstack/react-query"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { ICRMDeal } from "../../types/Deal"

interface AskGlyphicTextBoxProps {
    companyDomain: string
    placeholder: string
    input: string
    setInput: (input: string) => void
    submit: (input: string) => Promise<void>
    dealIdFilter?: string
    setDealIdFilter: (dealId?: string) => void
    dealSelectDisabled: boolean
}

export default function CompanyAskGlyphicTextBox(
    props: AskGlyphicTextBoxProps
) {
    const { data: deals } = useQuery(
        queries.companies.deals(props.companyDomain)
    )
    const [isLoading, setLoading] = useState<boolean>(false)
    const textareaRef = useRef<HTMLTextAreaElement>(null)

    const selectedDeal = useMemo(
        () => deals?.find((deal) => deal.id === props.dealIdFilter),
        [deals, props.dealIdFilter]
    )

    const handleInputChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { value } = event.target
        props.setInput(value)
    }

    const handleTextareaResize = () => {
        const textareaElement = textareaRef.current
        if (textareaElement) {
            textareaElement.style.height = "auto"
            textareaElement.style.height = `${textareaElement.scrollHeight}px`
        }
    }

    useEffect(() => {
        handleTextareaResize()
    }, [props.input])

    const handleSubmit = useCallback(
        async (event: React.FormEvent) => {
            event.preventDefault()
            props.setInput("")
            setLoading(true)
            try {
                await props.submit(props.input)
            } finally {
                setLoading(false)
            }
        },
        [props]
    )

    const hasMultipleDeals = deals && deals.length > 1

    return (
        <SimpleCard className="p-4">
            <form
                onSubmit={handleSubmit}
                className="flex flex-1 items-end space-x-2 w-full"
            >
                <textarea
                    name="message"
                    autoFocus
                    value={props.input}
                    onChange={handleInputChange}
                    onInput={handleTextareaResize}
                    placeholder={props.placeholder}
                    className="bg-transparent w-full rounded-lg resize-none outline-none pb-2"
                    style={{ height: "auto" }}
                    onKeyDown={(event) => {
                        if (isLoading || !props.input) return
                        if (event.key === "Enter" && !event.shiftKey) {
                            event.preventDefault()
                            handleSubmit(event)
                        }
                    }}
                    rows={1}
                    ref={textareaRef}
                />
                <div className="flex items-center gap-4">
                    {hasMultipleDeals && (
                        <DealSelector
                            deals={deals}
                            selectedDeal={selectedDeal}
                            onSelect={(deal) => props.setDealIdFilter(deal?.id)}
                            disabled={props.dealSelectDisabled}
                        />
                    )}
                    <PriorityButton
                        id="frigade-ask-glyphic-entry"
                        type={"submit"}
                        className="flex flex-row items-center px-4 py-2 w-fit md:min-w-fit"
                        disabled={isLoading || !props.input}
                    >
                        <Logo />
                        <span className="hidden md:block md:w-24">
                            Ask Glyphic
                        </span>
                    </PriorityButton>
                </div>
            </form>
        </SimpleCard>
    )
}

function DealSelector(props: {
    deals: ICRMDeal[]
    onSelect: (deal?: ICRMDeal) => void
    selectedDeal?: ICRMDeal
    disabled: boolean
}) {
    const ref = useRef<HTMLDivElement>(null)
    const [showDealsPopup, setShowDealsPopup] = useState<boolean>(false)
    const togglePopup = () => {
        if (!props.disabled) {
            setShowDealsPopup((prev) => !prev)
        }
    }

    // Close popup when clicking outside
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                setShowDealsPopup(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [])

    return (
        <div className="relative" ref={ref}>
            <div
                className={`flex items-center gap-2 ${
                    props.disabled ? "cursor-default" : "cursor-pointer"
                }`}
                onClick={togglePopup}
                data-tooltip-id="tooltip-explanation"
                data-tooltip-content={
                    props.disabled
                        ? "Start a new chat by clicking (+) to ask about a different deal"
                        : ""
                }
            >
                <div className="font-semibold whitespace-nowrap text-sm">
                    {props.selectedDeal?.name || "All deals"}
                </div>
                {!props.disabled && (
                    <FontAwesomeIcon icon={faChevronDown} className="w-3" />
                )}
            </div>
            {showDealsPopup && (
                <div className="absolute bottom-full mb-2 right-0 z-10">
                    <DealSelectorPopup
                        deals={props.deals}
                        onSelect={(deal) => {
                            props.onSelect(deal)
                            setShowDealsPopup(false)
                        }}
                    />
                </div>
            )}
        </div>
    )
}

function DealSelectorPopup(props: {
    deals: ICRMDeal[]
    onSelect: (deal?: ICRMDeal) => void
}) {
    return (
        <div className="bg-white shadow-lg rounded-lg max-w-xs w-max text-sm py-2">
            <div className="font-semibold pl-4 pr-6 py-3">Ask about</div>
            <ul>
                <li
                    className="pl-4 pr-6 py-2 w-full hover:bg-gray-100 cursor-pointer rounded"
                    onClick={() => props.onSelect(undefined)}
                >
                    All deals
                </li>
                {props.deals.map((deal, index) => (
                    <li
                        key={index}
                        className="pl-4 pr-6 py-2 w-full hover:bg-gray-100 cursor-pointer rounded"
                        onClick={() => props.onSelect(deal)}
                    >
                        {deal.name}
                    </li>
                ))}
            </ul>
        </div>
    )
}
