import tailwindColors from "tailwindcss/colors"

export const getColorTable = (
    shade: keyof typeof tailwindColors.amber
): string[] => [
    tailwindColors.amber[shade],
    tailwindColors.indigo[shade],
    tailwindColors.emerald[shade],
    tailwindColors.rose[shade],
    tailwindColors.sky[shade],
    tailwindColors.orange[shade],
    tailwindColors.violet[shade],
    tailwindColors.lime[shade],
    tailwindColors.fuchsia[shade],
    tailwindColors.teal[shade],
    tailwindColors.yellow[shade],
    tailwindColors.blue[shade],
    tailwindColors.red[shade],
    tailwindColors.green[shade],
    tailwindColors.pink[shade],
    tailwindColors.cyan[shade],
]
