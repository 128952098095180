import { useCallback, useEffect, useState } from "react"
import { useRef } from "react"
import _ from "lodash"

import axios from "axios"
import ReactPlayer from "react-player"
import { useParams } from "react-router-dom"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
    CallStatus,
    ICall,
    IParticipant,
    ITranscriptTurn,
} from "../../types/Call"
import { IAnnotation } from "../../types/Annotation"
import { CallTranscript } from "./CallTranscript"
import LoadingSpinner from "../common/LoadingSpinner"
import AskGlyphic from "../question-answering/AskGlyphic"
import { CallDetails } from "./CallDetails"
import {
    getTurnAtTimestamp,
    goToFirstTimestamp,
    goToTimestamp,
} from "./utils/mediaPlayer"
import { ErrorPage } from "../common/errorPage"
import { ResourceType } from "../question-answering/types/ResourceTypes"
import { CallOptions } from "./CallOptions"
import { CallAnalysis, Tab, Tabs, TabsEnum } from "./CallAnalysis"
import { sendFollowUpEmailForCall } from "../../utils/createEmailLink"
import { BackButton } from "../common/BackButton"
import { useNotification } from "../../providers/NotificationProvider"
import { NotificationType } from "../common/Notifcations"

import { FrigadeAskGlyphicTour } from "../Frigade"
import { LockIcon } from "../StatusIcon"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { queries } from "../../api/queries"

export default function Call() {
    const queryClient = useQueryClient()
    const [tabs, setTabs] = useState<Tab[]>([])
    const params = useParams()
    const playerRef = useRef<ReactPlayer | null>(null)
    const callId: string = params.call_id as string
    console.assert(!!callId, "Call page should always have a call id!")
    const [selectedAnnotation, setSelectedAnnotation] = useState<
        IAnnotation | undefined
    >()
    const [playerReady, setPlayerReady] = useState<boolean>(false)
    const [transcriptFollowMedia, setTranscriptFollowMedia] =
        useState<boolean>(true)
    const { addNotification } = useNotification()

    const selectAnnotation = (annotation: IAnnotation) => {
        setTranscriptFollowMedia(true)
        setSelectedAnnotation((prevSelectedAnnotation) => {
            if (prevSelectedAnnotation === annotation) {
                return undefined // unselect the annotation
            }
            return annotation
        })
    }

    const { data: call, error: callLoadError } = useQuery({
        ...queries.calls.byId(callId),
        refetchInterval: (query) => {
            return query.state.data?.status === CallStatus.InProgress
                ? 30000 // refetch every 30 seconds while call is in progress
                : false
        },
    })

    const { data: signedUrl, error: mediaError } = useQuery({
        ...queries.calls.media(callId),
        staleTime: Infinity, // Don't refetch media as it will disrupt playback
    })
    const playerHeight = signedUrl?.type === "video" ? "100%" : "50px"

    const mediaAccessDenied =
        mediaError &&
        axios.isAxiosError(mediaError) &&
        mediaError.response?.status === 403

    useEffect(() => {
        if (call && call.title) {
            document.title =
                call.title + " - " + process.env.REACT_APP_DOCUMENT_TITLE
        }

        return () => {
            // Reset the title when the component unmounts
            document.title = process.env.REACT_APP_DOCUMENT_TITLE!
        }
    }, [call])

    useEffect(() => {
        function addTabs(call: ICall) {
            const showStatsTab = call?.parties.some((p) => p.stats !== null) // TODO: remove when stats backfill completed
            const availableTabs = []
            availableTabs.push(Tabs[TabsEnum.Annotations])
            if (call.inferred_crm_field_values?.length) {
                availableTabs.push(Tabs[TabsEnum.CustomInsights])
            }
            if (showStatsTab) {
                availableTabs.push(Tabs[TabsEnum.TalkStats])
            }
            setTabs(availableTabs)
        }
        if (call) {
            addTabs(call)
        }
    }, [call])

    // If annotations have been selected, find the turns and highlight them
    // TODO: support highlighting parts of a turn, not just whole turns
    const [highlightedTurns, setHighlightedTurns] = useState<ITranscriptTurn[]>(
        []
    )

    // Set initial media timestamp to the first turn timestamp
    useEffect(() => {
        goToFirstTimestamp(playerRef, call?.transcript_turns)
    }, [playerReady, call?.transcript_turns])

    useEffect(() => {
        if (call && selectedAnnotation) {
            setHighlightedTurns(
                findTurnsInAnnotation(call.transcript_turns, selectedAnnotation)
            )
        }
    }, [call, selectedAnnotation])

    const sendEmail = useCallback(
        async (message: string) => {
            if (!call) return
            await sendFollowUpEmailForCall(message, call)
        },
        [call]
    )

    const updateAttendees = useMutation({
        mutationFn: async (newAttendees: IParticipant[]) => {
            if (!call || _.isEqual(newAttendees, call.parties)) return
            const response = await axios.put(
                process.env.REACT_APP_API_DOMAIN +
                    `/calls/${callId}/participants`,
                newAttendees
            )
            return response.data
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: queries.calls.byId(callId).queryKey,
            })
        },
        onError: (error) => {
            addNotification(
                "Failed to update attendees",
                "",
                NotificationType.Error
            )
        },
    })

    if (!!callLoadError) {
        let errorCode: number | undefined
        if (axios.isAxiosError(callLoadError)) {
            errorCode = callLoadError.response?.status
        }
        return (
            <ErrorPage
                error={{
                    code: errorCode,
                    message: "Failed to load call.",
                }}
            />
        )
    }

    if (!call) {
        return <LoadingSpinner />
    }

    const callTitle = call.title ?? "Untitled call"

    function onMediaProgress(mediaProgress: {
        played: number
        playedSeconds: number
        loaded: number
        loadedSeconds: number
    }) {
        const turn = getTurnAtTimestamp(
            call!.transcript_turns,
            mediaProgress.playedSeconds
        )

        // If we just highlighted turns from pressing an annotation, only move
        // to the next highlight once the media has moved past the first
        // selected turn.
        if (turn !== null && turn !== highlightedTurns[0]) {
            setHighlightedTurns([turn])
        }
    }

    function resumeTranscriptScroll() {
        setTranscriptFollowMedia(true)
        setSelectedAnnotation(undefined) // unselect any annotiation
    }

    const callIsCompleted = call.status === CallStatus.Completed
    const callIsCancelled = call.status === CallStatus.Cancelled
    const callIsInProgress =
        call.status === CallStatus.InProgress || !call.status
    const callIsFailed = call.status === CallStatus.Failed
    const canViewDetails = callIsCompleted && call.can_view

    return (
        <div>
            <div className="md:h-12 flex flex-row justify-between items-center px-6 py-2 border-b space-x-4 bg-white">
                <BackButton defaultCaption="All calls" defaultTo={"/calls"} />
                {call.can_view && <CallOptions call={call} />}
            </div>
            <div className="grid md:grid-cols-5 max-w-7xl py-3 mx-auto md:h-[calc(100vh-48px)]">
                <div className="overflow-y-auto col-span-3">
                    <section className="px-6 pb-6 space-y-4">
                        <div className="space-y-5">
                            <div className="flex flex-row items-center gap-2 flex-nowrap">
                                {call.is_private && <LockIcon />}
                                <h1 className="text-2xl font-bold">
                                    {callTitle}
                                </h1>
                            </div>
                            <CallDetails
                                call={call}
                                showTags={call.can_view}
                                showCrmRecords={call.can_view}
                                onChange={(newAttendees: IParticipant[]) => {
                                    updateAttendees.mutate(newAttendees)
                                }}
                            />
                        </div>
                        {canViewDetails && (
                            <>
                                <FrigadeAskGlyphicTour />
                                <AskGlyphic
                                    resourceId={callId}
                                    type={ResourceType.Calls}
                                    sendEmail={sendEmail}
                                />

                                <CallAnalysis
                                    tabs={tabs}
                                    call={call}
                                    selectedAnnotation={selectedAnnotation}
                                    selectAnnotation={selectAnnotation}
                                    playerRef={playerRef}
                                />
                            </>
                        )}
                        {callIsCancelled && (
                            <CallCancelledWarning
                                statusReason={call.status_reason}
                            />
                        )}
                        {callIsInProgress && <CallInProgressWarning />}
                        {callIsFailed && <CallFailedWarning />}
                        {!call.can_view && <CallPrivateWarning />}
                    </section>
                </div>

                <div className="flex flex-col col-span-2 overflow-y-auto p-6 space-y-6">
                    <div>
                        {mediaAccessDenied ? (
                            <MediaAccessDeniedWarning />
                        ) : (
                            signedUrl && (
                                <div className="rounded-lg overflow-hidden">
                                    <ReactPlayer
                                        id="media-player"
                                        url={signedUrl.signed_url || undefined}
                                        controls={true}
                                        ref={playerRef}
                                        height={playerHeight}
                                        width="100%"
                                        onProgress={onMediaProgress}
                                        onPlay={() => {
                                            resumeTranscriptScroll()
                                        }}
                                        onReady={() => setPlayerReady(true)}
                                    />
                                </div>
                            )
                        )}
                    </div>
                    <div className="flex flex-grow overflow-y-auto rounded-xl">
                        <CallTranscript
                            transcriptTurns={call.transcript_turns}
                            highlightedTurns={highlightedTurns}
                            parties={call.parties}
                            goToTimestamp={goToTimestamp(playerRef)}
                            followMedia={transcriptFollowMedia}
                            setFollowMedia={setTranscriptFollowMedia}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

function CallCancelledWarning(props: { statusReason: string | null }) {
    return (
        <div className="bg-gray-900 text-white rounded-lg p-3 text-center">
            <div>This call could not be processed.</div>
            {props.statusReason && <div>Reason: {props.statusReason}</div>}
        </div>
    )
}

function CallInProgressWarning() {
    return (
        <div className="bg-gray-900 text-white rounded-lg p-3 text-center space-y-4">
            <div>
                This call is being processed.
                <br />
                Please check back in a few minutes.
            </div>
            <FontAwesomeIcon
                className={"font-bold w-6 h-6"}
                icon={faSpinner}
                spin
            />
        </div>
    )
}

function CallFailedWarning() {
    return (
        <div className="bg-gray-900 text-white rounded-lg p-3 text-center">
            <div>This call could not be processed.</div>
        </div>
    )
}

function CallPrivateWarning() {
    return (
        <div className="flex flex-row gap-2 bg-gray-900 text-white rounded-lg p-3 items-center justify-center">
            <LockIcon />
            <div>
                This call is private. Only participants can view its details.
            </div>
        </div>
    )
}

function MediaAccessDeniedWarning() {
    return (
        <div className="flex flex-row gap-2 bg-gray-100 text-gray-500 rounded-lg p-3 items-center justify-center">
            <LockIcon />
            <div>Access to media for this call is restricted.</div>
        </div>
    )
}

function findTurnsInAnnotation(
    turns: ITranscriptTurn[],
    annotation: IAnnotation
): ITranscriptTurn[] {
    // Find first and last turns that cover the annotation
    const firstTurnIndex = turns.findIndex(
        (turn) => turn.turn_end > annotation.start
    )
    const lastTurnIndex = turns.findIndex(
        (turn) => turn.turn_start > annotation.end
    )
    // handle case where annotation is after last turn
    if (lastTurnIndex === -1) {
        return turns.slice(firstTurnIndex)
    }

    // return all turns between these two
    return turns.slice(firstTurnIndex, lastTurnIndex)
}
