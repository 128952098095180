import axios from "axios"

export async function sendBotToMeeting(
    meetingId: string | null,
    meetingUrl: string
) {
    await axios.post(`${process.env.REACT_APP_API_DOMAIN}/call_bot`, {
        meeting_id: meetingId,
        meeting_url: meetingUrl.trim(),
    })
}
