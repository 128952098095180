import { createQueryKeys } from "@lukemorales/query-key-factory"
import axios from "axios"

export const versionQueries = createQueryKeys("version", {
    frontendVersion: () => ({
        queryKey: ["version"],
        queryFn: async () => {
            const response = await axios.get(
                `${process.env.REACT_APP_API_DOMAIN}/version`
            )
            return response.data.frontend as string
        },
    }),
})
