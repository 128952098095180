import _ from "lodash"
import {
    CartesianGrid,
    Line,
    LineChart as RechartsLineChart,
    ReferenceArea,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts"
import {
    Granularity,
    InsightResult,
    LineChartBucket,
} from "../../../types/Insights"
import tailwindColors from "tailwindcss/colors"
import {
    getSimpleDateWithoutYear,
    getStartOfWeek,
    getMonth,
} from "../../../utils/datetime"
import { useMemo, useState, useCallback } from "react"
import { ToggleExpandedViewButton } from "./ToggleExpandedViewButton"
import { createCustomTooltip } from "./CustomTooltip"
import { SelectionState } from "./SelectionState"
import { Legend } from "./Legend"
import { getColorTable } from "../utils/colors"

const NUMBER_OF_SELECTED_OPTIONS = 5
const NUMBER_OF_VISIBLE_OPTIONS = 8

function getLabelFormatter(granularity: Granularity) {
    switch (granularity) {
        case Granularity.WEEKLY:
            return getStartOfWeek
        case Granularity.MONTHLY:
            return getMonth
        default:
            return getSimpleDateWithoutYear
    }
}

function calculateMaxValue(
    data: LineChartBucket[],
    isOptionSelected: (option: string) => boolean
): number {
    const maxValues = data.map((result) => {
        const selectedValues = Object.entries(result.values)
            .filter(([key, _]) => isOptionSelected(key))
            .map(([_, value]) => value)

        // If no values are selected, return max of all values
        const valuesToConsider =
            selectedValues.length > 0
                ? selectedValues
                : Object.values(result.values)

        return Math.max(1, ...valuesToConsider)
    })
    return Math.max(...maxValues) + 1 // +1 to avoid cropping the top line
}

export function LineChart(props: {
    insight: InsightResult
    granularity: Granularity
    data: LineChartBucket[]
}) {
    const [viewAllOptions, setViewAllOptions] = useState(false)

    const sortedOptions = useMemo(
        () => getOptionsSortedByTotal(props.data),
        [props.data]
    )
    const [selectionState, setSelectionState] = useState<SelectionState>(
        () => ({
            selected: new Set(
                sortedOptions
                    .slice(0, NUMBER_OF_SELECTED_OPTIONS)
                    .map(([key, _]) => key)
            ),
            hovered: null,
        })
    )
    const [highlightedOption, setHighlightedOption] = useState<string | null>(
        null
    )

    // This contains only the visible options. Used to display the right
    // collapsed number of options in the legend. Should be the same as
    // `sortedOptions` when showing the expanded view.
    const visibleOptions = useMemo(() => {
        if (viewAllOptions) {
            return sortedOptions
        }
        return sortedOptions.slice(0, NUMBER_OF_VISIBLE_OPTIONS)
    }, [sortedOptions, viewAllOptions])

    const isOptionSelected = useCallback(
        (option: string) => {
            return (
                selectionState.hovered === option ||
                (!selectionState.hovered && selectionState.selected.has(option))
            )
        },
        [selectionState]
    )

    const toggleOption = useCallback((option: string) => {
        setSelectionState((prevState) => {
            const newSelected = new Set(prevState.selected)
            if (newSelected.has(option)) {
                newSelected.delete(option)
            } else {
                newSelected.add(option)
            }
            return { ...prevState, selected: newSelected }
        })
    }, [])

    const setHoveredOption = useCallback((option: string | null) => {
        setHighlightedOption(option)
    }, [])

    return (
        <div className="w-full bg-gray-50 rounded-lg p-4">
            <h2 className="text-lg mb-8">
                <b>Mentions</b> of <b>{props.insight.displayName}</b> in calls
                over time
            </h2>
            <div className="grid grid-cols-1 lg:grid-cols-3">
                <div className="col-span-3 lg:col-span-1">
                    <div className="flex flex-col gap-2">
                        <Legend
                            insight={props.insight}
                            colors={getColorTable("500")}
                            totalCounts={visibleOptions}
                            selectionState={selectionState}
                            toggleSelectedOption={toggleOption}
                            setHoveredOption={setHoveredOption}
                            isOptionSelected={isOptionSelected}
                        />
                        {sortedOptions.length > NUMBER_OF_VISIBLE_OPTIONS && (
                            <ToggleExpandedViewButton
                                viewAllOptions={viewAllOptions}
                                setViewAllOptions={setViewAllOptions}
                            />
                        )}
                    </div>
                </div>
                <div className="col-span-3 lg:col-span-2 h-96 w-full">
                    <LineChartView
                        data={props.data}
                        granularity={props.granularity}
                        isOptionSelected={isOptionSelected}
                        sortedOptions={sortedOptions}
                        highlightedOption={highlightedOption}
                    />
                </div>
            </div>
        </div>
    )
}

function getOptionsSortedByTotal(
    results: LineChartBucket[]
): [string, number][] {
    const totalCounts: Record<string, number> = {}
    for (const result of results) {
        for (const [key, value] of Object.entries(result.values)) {
            totalCounts[key] = (totalCounts[key] || 0) + value
        }
    }

    return _.sortBy(Object.entries(totalCounts), ([_, v]) => -v)
}

function LineChartView(props: {
    data: LineChartBucket[]
    granularity: Granularity
    sortedOptions: [string, number][]
    highlightedOption: string | null
    isOptionSelected: (option: string) => boolean
}) {
    const data = props.data
    const toLabel = getLabelFormatter(props.granularity)
    const isOptionSelected = props.isOptionSelected
    const sortedOptions = props.sortedOptions
    const highlightedOption = props.highlightedOption

    const CustomTooltip = useMemo(
        () => createCustomTooltip(isOptionSelected),
        [isOptionSelected]
    )

    const maxValue: number | "dataMax" = useMemo(() => {
        return calculateMaxValue(data, isOptionSelected)
    }, [data, isOptionSelected])

    const [selectedOptions, unselectedOptions] = useMemo(() => {
        const selectedColorTable = getColorTable("500")
        const unselectedColorTable = getColorTable("100")

        const selected = []
        const unselected = []
        for (let i = sortedOptions.length - 1; i >= 0; i--) {
            const [option, value] = sortedOptions[i]

            const isSelected =
                isOptionSelected(option) && highlightedOption === null
            const isHighlighted = highlightedOption === option

            if (isSelected || isHighlighted) {
                const color = selectedColorTable[i % selectedColorTable.length]
                const optionObj = { option, value, color }
                selected.push(optionObj)
            } else {
                const color =
                    unselectedColorTable[i % unselectedColorTable.length]
                const optionObj = { option, value, color }
                unselected.push(optionObj)
            }
        }

        return [selected, unselected]
    }, [sortedOptions, isOptionSelected, highlightedOption])

    const incompleteDataRange = useMemo(() => {
        if (data.length > 2) {
            // The latest data point is incomplete, so we highlight the range after the previous point
            return {
                x0: data[data.length - 2].key,
                x1: data[data.length - 1].key,
            }
        }
        return undefined
    }, [data])

    return (
        <ResponsiveContainer>
            <RechartsLineChart
                data={data.map((result) => ({
                    key: result.key,
                    ...result.values,
                }))}
                margin={{
                    top: 20,
                    bottom: 20,
                }}
            >
                <CartesianGrid
                    vertical={false}
                    stroke={tailwindColors.gray[200]}
                />
                <XAxis
                    dataKey="key"
                    tickLine={false}
                    axisLine={false}
                    tickMargin={12}
                    tick={(tickObject) => (
                        <XAxisTick
                            x={tickObject.x}
                            y={tickObject.y}
                            value={toLabel(tickObject.payload.value)}
                            isLast={
                                tickObject.payload.value ===
                                data[data.length - 1].key
                            }
                        />
                    )}
                />
                <YAxis
                    type="number"
                    axisLine={false}
                    tickLine={false}
                    fontSize={12}
                    allowDataOverflow
                    domain={["dataMin", maxValue]}
                    allowDecimals={false}
                />
                <Tooltip
                    content={<CustomTooltip />}
                    labelFormatter={toLabel}
                    wrapperStyle={{ zIndex: 1000 }}
                />
                {unselectedOptions.map(({ option, color }) => (
                    <Line
                        key={option}
                        name={option}
                        type="monotone"
                        dataKey={option}
                        stroke={color.replace("500", "100")}
                        style={{ filter: "grayscale(0.5)" }}
                        strokeWidth={4}
                        dot={false}
                        isAnimationActive={false}
                    />
                ))}
                {selectedOptions.map(({ option, color }) => (
                    <Line
                        key={option}
                        name={option}
                        type="monotone"
                        dataKey={option}
                        stroke={color}
                        strokeWidth={4}
                        dot={false}
                        isAnimationActive={false}
                    />
                ))}
                {incompleteDataRange && (
                    <ReferenceArea
                        y1={0}
                        y2={maxValue}
                        x1={incompleteDataRange.x0}
                        x2={incompleteDataRange.x1}
                        fill={tailwindColors.gray[50]}
                        fillOpacity={0.8}
                    />
                )}
            </RechartsLineChart>
        </ResponsiveContainer>
    )
}

function XAxisTick({
    x,
    y,
    value,
    isLast,
}: {
    x: number
    y: number
    value: string
    isLast: boolean
}) {
    const rectPaddingX = 12
    const rectHeight = 15
    const fontSize = 12

    return (
        <g transform={`translate(${x}, ${y})`}>
            {isLast && (
                <rect
                    x={(-value.length * fontSize) / 4 - rectPaddingX / 2} // Center the rectangle over text
                    y={-rectHeight / 2}
                    width={(value.length * fontSize) / 2 + rectPaddingX} // Fixed padding based on length
                    height={rectHeight}
                    fill={tailwindColors.gray[400]}
                    rx={4}
                    ry={4}
                />
            )}
            <text
                data-tooltip-id="tooltip-explanation"
                data-tooltip-content={
                    isLast
                        ? "Partial data: This point covers a period still in progress"
                        : ""
                }
                y={4} // Vertical alignment
                fontSize={fontSize}
                fill={isLast ? tailwindColors.white : tailwindColors.gray[500]}
                fontWeight={isLast ? "bold" : "normal"}
                textAnchor="middle"
            >
                {value}
            </text>
        </g>
    )
}
