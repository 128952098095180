import { SetupSuggestion, SetupSuggestionProps } from "./SetupSuggestion"
import glyphicLogo from "../assets/logomark_delta.svg"
import { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faReadme } from "@fortawesome/free-brands-svg-icons"
import { faCircleQuestion, faRocket } from "@fortawesome/free-solid-svg-icons"
import { showPylon } from "../utils/pylon"

const ONBAORDING_VIDEO =
    "https://www.loom.com/embed/f860a5cffb054b92827faf582cc820f6?sid=562fa2da-2958-47fc-9bc1-26560c22776e"

export function SetupBanner() {
    const [suggestions, setSuggestions] = useState<SetupSuggestionProps[]>([])

    useEffect(() => {
        const onDismiss = (name: string) => {
            localStorage.setItem(`suggestions.${name}`, "dismissed")
            setSuggestions((suggestions) =>
                suggestions.filter((suggestion) => suggestion.name !== name)
            )
        }
        async function getSuggestions() {
            const suggestions: SetupSuggestionProps[] = []

            if (!wasDismissed("welcome")) {
                suggestions.push({
                    name: "welcome",
                    children: (
                        <div className="flex flex-row items-center gap-3">
                            <img
                                src={glyphicLogo}
                                alt="welcome"
                                className="h-12"
                            />
                            <div>
                                <div className="font-bold">
                                    Welcome to Glyphic!
                                </div>
                                <div className="text-gray-600">
                                    This is your personal home space to get an
                                    overview of your most recent and upcoming
                                    calls and deals.
                                </div>
                            </div>
                        </div>
                    ),
                    onDismiss: () => onDismiss("welcome"),
                })
            }

            if (!wasDismissed("onboarding_video")) {
                suggestions.push({
                    name: "onboarding_video",
                    children: (
                        <div>
                            <div className="flex flex-row items-center gap-3">
                                <FontAwesomeIcon
                                    icon={faRocket}
                                    className="text-4xl"
                                />
                                <div>
                                    <div className="font-bold">Get started</div>
                                    <div className="text-gray-600">
                                        Check out our video tour to get started
                                        with Glyphic.
                                    </div>
                                </div>
                            </div>
                            <div className="relative pb-[64.98194945848375%] h-0 m-2">
                                <iframe
                                    title="Glyphic product demo video"
                                    src={ONBAORDING_VIDEO}
                                    allowFullScreen
                                    className="absolute top-0 left-0 w-full h-full rounded"
                                />
                            </div>
                        </div>
                    ),
                    onDismiss: () => onDismiss("onboarding_video"),
                })
            }

            if (!wasDismissed("documentation")) {
                suggestions.push({
                    name: "documentation",
                    children: (
                        <div className="flex flex-row items-center gap-3">
                            <FontAwesomeIcon
                                icon={faReadme}
                                className="text-4xl"
                            />
                            <div>
                                <div className="font-bold">
                                    Want to know more?
                                </div>
                                <div className="text-gray-600">
                                    <span>Click </span>
                                    <button
                                        className="underline space-x-1 hover:font-semibold"
                                        onClick={showPylon}
                                    >
                                        <FontAwesomeIcon
                                            icon={faCircleQuestion}
                                        />
                                        <span>Help</span>
                                    </button>{" "}
                                    <span>
                                        to learn more about how to use Glyphic.
                                    </span>
                                </div>
                            </div>
                        </div>
                    ),
                    onDismiss: () => onDismiss("documentation"),
                })
            }

            return suggestions
        }

        getSuggestions().then((suggestions) => {
            setSuggestions(suggestions)
        })
    }, [])

    if (suggestions.length === 0) {
        return null
    }

    return (
        <div className="space-y-2">
            <div className="flex flex-col gap-2">
                {suggestions.map((suggestion) => (
                    <SetupSuggestion {...suggestion} key={suggestion.name} />
                ))}
            </div>
        </div>
    )
}

function wasDismissed(suggestionName: string) {
    return localStorage.getItem(`suggestions.${suggestionName}`) === "dismissed"
}
