import { createQueryKeys } from "@lukemorales/query-key-factory"
import axios from "axios"
import { ICRMDeal } from "../../types/Deal"

export const dealsQueries = createQueryKeys("deals", {
    getByIds: (dealIds: string[]) => ({
        queryKey: ["deals", dealIds],
        queryFn: async () => {
            const response = await axios.post(
                `${process.env.REACT_APP_API_DOMAIN}/deals`,
                dealIds
            )
            return response.data as ICRMDeal[]
        },
    }),
})
