import { useCallback, useEffect, useMemo } from "react"
import { NotificationType } from "../components/common/Notifcations"
import { useNotification } from "../providers/NotificationProvider"
import { useQuery } from "@tanstack/react-query"
import { queries } from "../api/queries"
import { getCacheValue, setCacheValue } from "../utils/localStorageCache"

const DISMISSED_ANNOUNCEMENTS_CACHE_KEY = "dismissed_announcements"

export function AnnouncementChecker() {
    const { addNotification } = useNotification()
    const dissmissedAnnouncements = useMemo(
        () => getCacheValue<string[]>(DISMISSED_ANNOUNCEMENTS_CACHE_KEY, []),
        []
    )

    // Periodically ping maverick api to check if there's a new announcement
    const refetchInterval = 1000 * 60 * 10 // 10 minutes

    const { data: announcements } = useQuery({
        ...queries.announcements.get(),
        staleTime: refetchInterval,
        refetchInterval: refetchInterval,
        refetchIntervalInBackground: false,
    })

    const dismissAnnouncement = useCallback(
        (announcementId: string) => {
            setCacheValue(
                DISMISSED_ANNOUNCEMENTS_CACHE_KEY,
                dissmissedAnnouncements.concat(announcementId)
            )
        },
        [dissmissedAnnouncements]
    )

    useEffect(() => {
        const newAnnouncements = announcements?.filter(
            (announcement) => !dissmissedAnnouncements.includes(announcement.id)
        )
        newAnnouncements?.forEach((announcement) => {
            const onClose = () => dismissAnnouncement(announcement.id)
            addNotification(
                announcement.title,
                announcement.message,
                NotificationType.Broadcast,
                onClose
            )
        })
    }, [
        addNotification,
        announcements,
        dissmissedAnnouncements,
        dismissAnnouncement,
    ])

    return null
}
