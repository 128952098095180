import Session from "supertokens-web-js/recipe/session"
import { fetchEventSource } from "@microsoft/fetch-event-source"

/**
 * Pipes the response from the given url to the given receiver function.
 * Assumes the endpoint follows Glyphic's SSE format.
 *
 * @param url url to pipe the response from
 * @param receiver function to receive the response. The function should return
 *  true if it wants to continue receiving responses, or false if it wants to
 *  stop receiving responses.
 * @param body optional body to send with the request
 */

export async function pipeResponse(
    url: string,
    receiver: (delta: string) => boolean,
    body?: Record<string, unknown>
): Promise<void> {
    const token = await Session.getAccessToken()

    const ctrl = new AbortController()

    return fetchEventSource(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
        async onopen(response) {
            if (response.ok) {
                return
            } else {
                throw new Error("Failed to open connection")
            }
        },
        onmessage(event) {
            if (event.event === "answer") {
                const data = JSON.parse(event.data)
                if (!receiver(data.answer)) {
                    ctrl.abort()
                }
            } else if (event.event === "done") {
                ctrl.abort()
            }
        },
        onerror(err) {
            throw err
        },
        signal: ctrl.signal,
        // Must be set to true. Default is false which means the connection will be closed when the tab is hidden
        // https://github.com/Azure/fetch-event-source/issues/36
        openWhenHidden: true,
    })
}
