import { createQueryKeys } from "@lukemorales/query-key-factory"
import axios from "axios"
import { ICall, ISharedCallPreview, Media } from "../../types/Call"
import { ICrmObjectReference } from "../../components/crm/types/Crm"

export const callQueries = createQueryKeys("calls", {
    byId: (callId: string) => ({
        queryKey: ["call", callId],
        queryFn: async () => {
            const response = await axios.get(
                process.env.REACT_APP_API_DOMAIN + "/calls/" + callId
            )
            return response.data as ICall
        },
    }),
    media: (callId: string) => ({
        queryKey: ["call/media", callId],
        queryFn: async () => {
            const response = await axios.get(
                process.env.REACT_APP_API_DOMAIN + "/calls/" + callId + "/media"
            )
            return response.data as Media
        },
    }),

    shares: (callId: string) => ({
        queryKey: ["call/shares", callId],
        queryFn: async () => {
            const response = await axios.get(
                process.env.REACT_APP_API_DOMAIN +
                    "/calls/" +
                    callId +
                    "/shares"
            )
            return response.data as ISharedCallPreview[]
        },
    }),

    crmObjects: (callId: string) => ({
        queryKey: [`call/crm/objects`, callId],
        queryFn: async () => {
            const response = await axios.get(
                process.env.REACT_APP_API_DOMAIN +
                    `/calls/${callId}/crm/objects`
            )
            return response.data as ICrmObjectReference[]
        },
    }),
})
